export default class BaseB2CAuthentication {
  constructor(b2c) {
    this.b2c = b2c;
  }

  async login() {}

  async silentLogin() {}

  async logout() {}

  async refresh() {
    return undefined;
  }
}
