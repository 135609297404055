import moment from 'moment-timezone';

export const useHaCtxSelectors = state => {
  return {
    haDetailsPage: () => ({
      patient: state?.eligibility?.customer?.identifier?.value,
    }),
    haTypesPage: () => ({
      loading: state?.loading,
      eligibilityData: state.eligibility?.['eligible-for'],
      pro4Eligibility: state?.eligibility?.pro4Eligible,
    }),
    haTypeCard: () => ({
      patientId: state?.eligibility?.customer?.identifier?.value,
      payorId: state?.details?.payorId,
    }),
    locations: () => {
      return state.locations;
    },
    locationsWithCoords: () => {
      return state.locationsWithCoords;
    },
    locationsOrderedByDistance: () => {
      return state.locationsOrderedByDistance;
    },
    haSlotsPage: () => ({
      loading: state?.loading,
      slotsData: state?.slots,
      appointmentType: state?.haType?.typeId,
      appointmentTypeFormatted: state?.haType?.typeId.replace(/\D/g, ''),
      activityId: state?.details?.activityId,
      patient: state?.eligibility?.customer?.identifier?.value,
      locations: state?.selectedLocation?.locationId.replace(/\D/g, ''),
      payorId: state?.eligibility?.payor?.identifier?.value,
      haTypeTitle: state?.haType?.typeTitle,
      haLocationName: state?.selectedLocation?.locationName,
      sexAssignedAtBirth: state?.details?.sexAssignedAtBirth,
      gender: state?.details?.gender,
    }),
    appointmentProposed: (prop = 'appointmentProposed') => {
      return state.appointmentProposed?.[prop];
    },
    haConfirmPage: () => ({
      appointmentId: parseInt(state?.appointmentProposed?.text.replace(/\D/g, '')),
      patient: state?.eligibility?.customer?.identifier?.value,
      startTime: state?.selectedSlot?.startTime,
      endTime: state?.selectedSlot?.endTime,
      practitionerGenderPreference: state?.selectedSlot?.practitionerGenderPreference,
      haTypeTitle: state?.haType?.typeTitle,
      haLocationName: state?.selectedLocation?.locationName,
    }),
    appointmentBooked: (prop = 'appointmentBooked') => {
      return state.appointmentBooked?.[prop];
    },
    patientUpdated: (prop = 'patientUpdated') => {
      return state.patientUpdated?.[prop];
    },
    haConfirmedPage: () => ({
      startTime: state?.selectedSlot?.startTime,
      endTime: state?.selectedSlot?.endTime,
      appointmentDate: moment(state?.selectedSlot?.startTime).format('dddd Do MMMM'),
      slotStartTime: moment(state?.selectedSlot?.startTime).format('HH:mm'),
      locationName: state?.selectedLocation?.locationName,
      locationLineOne: state?.selectedLocation?.locationLineOne,
      locationLineTwo: state?.selectedLocation?.locationLineTwo,
      locationTownCity: state?.selectedLocation?.locationTownCity,
      locationPostcode: state?.selectedLocation?.locationPostcode,
      locationText: state?.selectedLocation?.locationText,
      haTypeTitle: state?.haType?.typeTitle,
      haTypeDetails: state?.haType?.typeDetails,
      haTypeDuration: state?.haType?.typeDuration,
      dateOfBirth: state?.details?.dateOfBirth,
      sexAssignedAtBirth: state?.details?.sexAssignedAtBirth,
      gender: state?.details?.gender,
      eligibleForMammogram: state?.eligibility?.eligibleForMammogram,
      activityId: state?.details?.activityId,
      payorId: state?.eligibility?.payor?.identifier?.value,
    }),
    appointments: (prop = 'appointments') => {
      return state.appointments?.[prop];
    },
    appointmentCancelled: (prop = 'appointmentCancelled') => {
      return state?.appointmentCancelled;
    },
    isEmptyState: () => {
      if (Object.keys(state).length === 0 || Object.keys(state).length === 1) {
        return true;
      }
    },
    locationName: () => state.locationName,
  };
};
