import React, { useEffect } from 'react';
import GoogleAutocomplete from './GoogleAutocomplete';
import { useScript } from '../hooks/useScript';
import errorActions from '../actions/errorActions';
import { ScriptStates } from '../enums/booking';

import './GoogleAutocomplete.sass';

export default ({ getResponseData, clearResponseList, defaultValue }) => {
  const { log: logError } = errorActions();
  const googleAPIStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_KEY}&libraries=places`,
    {
      removeOnUnmount: true,
    },
  );
  useEffect(() => {
    if (googleAPIStatus === ScriptStates.Error) {
      logError({
        error: 'CUSTOM',
        text: 'Unfortunately, we can’t process your booking online due to an error. Please call us on 0300 123 1844 to complete your booking.',
      });
    }
  }, [googleAPIStatus]);

  return (
    <>
      {googleAPIStatus === ScriptStates.Ready && (
        <GoogleAutocomplete
          getResponseData={getResponseData}
          clearResponseList={clearResponseList}
          defaultValue={defaultValue}
        />
      )}
    </>
  );
};
