import React, { useReducer, createContext, useMemo, useContext } from 'react';
import { haReducer } from '../reducers/healthAssessmentReducer';
import { useHaCtxSelectors, useHaActions } from '../hooks/useHealthAssessment.js';

const HealthAssessmentContext = createContext();

const HealthAssessmentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(haReducer, { appointmentCancelled: false });
  const provider = useMemo(() => [state, dispatch], [state]);

  return (
    <HealthAssessmentContext.Provider value={provider}>{children}</HealthAssessmentContext.Provider>
  );
};

function useHealthAssessmentCtx() {
  const context = useContext(HealthAssessmentContext);
  if (context === undefined) {
    throw new Error('useHealthAssessmentCtx must be used within a HealthAssessmentProvider');
  }
  const [state, dispatch] = context;
  const haCtxActions = useHaActions(dispatch);
  const haCtxSelectors = useHaCtxSelectors(state);
  return { state, haCtxActions, haCtxSelectors };
}
export { HealthAssessmentProvider, useHealthAssessmentCtx };
