import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import useAuthentication from '../useAuthentication';
import useBookingEngine from '../useBookingEngine';
import eventsActions from '../../actions/eventsActions';
import getEventAction from './getEventAction';
import getTimeSlotsAction from './getTimeSlotsAction';

export default (resourceId, reservationId) => {
  const { profile } = useAuthentication();
  const bookingEngine = useBookingEngine();
  const actions = eventsActions(bookingEngine);
  const { fetchForReservation, createEventReservation } = actions;
  const { reservation, items, selected_event: selectedEvent } = useSelector(({ events }) => events);

  useEffect(() => {
    fetchForReservation(resourceId, reservationId);
  }, []);

  const item = useMemo(
    () =>
      reservation
        ? {
            ...reservation,
            type: 'Event',
            action: getEventAction(reservation, actions),
          }
        : null,
    [reservation],
  );

  const timeslots = useMemo(
    () =>
      orderBy(Object.values(items), ['from_date'], 'asc').map(i => ({
        ...i,
        type: 'Event',
        action: getTimeSlotsAction(i, actions),
      })),
    [items],
  );

  return {
    reservation: item,
    timeslots,
    selectedEvent,
    profile,
    createEventReservation,
  };
};
