import TagManager from 'react-gtm-module';
import BaseTagManager from './BaseTagManager';

const DEFAULTS = {
  tagManagerId: process.env.REACT_APP_GTM_ID,
};

export default class WebTagManager extends BaseTagManager {
  constructor({ tagManagerId } = DEFAULTS) {
    // The browser tag manager implementation does not return any instance but
    // relies on the window variables, therefore we instantiate with null.
    TagManager.initialize({
      gtmId: tagManagerId,
    });
    super(null);
  }

  async pushEvent(data) {
    TagManager.dataLayer({
      dataLayer: data,
    });
  }
}
