import { useEffect } from 'react';
import useBookingEngine from './useBookingEngine';
import { VALIDATORS } from '../enums/validators';
import validatePostcode from '../utils/validatePostcode';

export default ({ setValid, isValid, value, validator, required } = {}) => {
  const bookingEngine = useBookingEngine();

  const requiredValidator = () => Boolean(value);

  const validate = async () => {
    if (validator === VALIDATORS.loqate) {
      setValid(await validatePostcode(value, bookingEngine));
    } else if (validator === VALIDATORS.custom) {
      setValid(await isValid());
    } else if (required) {
      setValid(requiredValidator());
    } else {
      setValid(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(
      () => {
        validate();
      },
      validator === VALIDATORS.loqate ? 500 : 0,
    );
    return () => clearTimeout(timer);
  }, [value]);

  return [];
};
