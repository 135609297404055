import moment, { isMoment } from 'moment-timezone';
import { range } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import useSelect from './useSelect';

export default (
  nrOfDays,
  {
    startDate = moment().startOf('day'),
    endDate = null,
    extraData = date => ({}),
    isDisabled = day => false,
    onChange = (firstDay, nrOfDays, days) => {},
    dependencies = [],
  } = {},
) => {
  const [firstDay, setFirstDay] = useState(startDate);

  const allDays = useMemo(() => {
    const days = range(nrOfDays).map((_, index) => {
      const date = firstDay.clone().add(index, 'day');

      return { ...extraData(date), date };
    });
    return days;
  }, [firstDay, nrOfDays, ...dependencies]);

  useEffect(() => {
    onChange(firstDay, nrOfDays, allDays);
  }, [firstDay, nrOfDays]);

  const actions = useMemo(
    () => ({
      next: () =>
        setFirstDay(() => {
          const newDay = firstDay.clone().add(nrOfDays, 'day');

          return isMoment(endDate) && newDay.isAfter(endDate) ? firstDay : newDay;
        }),
      prev: () =>
        setFirstDay(() => {
          const newDay = firstDay.clone().subtract(nrOfDays, 'day');

          return newDay.isBefore(startDate) ? startDate : newDay;
        }),
      nextMonth: () =>
        setFirstDay(() => {
          const newDay = firstDay.clone().add(1, 'month').startOf('month');

          return isMoment(endDate) && newDay.isAfter(endDate) ? firstDay : newDay;
        }),
      prevMonth: () =>
        setFirstDay(() => {
          const newDay = firstDay.clone().subtract(1, 'month');

          return newDay.isBefore(startDate) ? startDate : newDay;
        }),
      refresh: () => {
        setFirstDay(moment().startOf('day'));
      },
    }),
    [firstDay],
  );

  return [
    ...useSelect(allDays, {
      dependencies: [allDays.length],
      isDisabled,
    }),
    actions,
    setFirstDay,
  ];
};
