import moment from 'moment-timezone';
import { v4 as uuid4 } from 'uuid';
import genQueryParams from '../utils/genQueryParams';

class BookingEngine {
  static getInstance(accessToken, refresh = async () => {}, logError = async () => {}) {
    if (this.instance === undefined) {
      this.instance = new this();
    }

    this.instance.token = accessToken;
    this.instance.refresh = refresh;
    this.instance.logError = logError;

    return this.instance;
  }

  constructor() {
    this.apimEnabled = global.BOOKING_ENGINE_VIA_APIM;
    this.apimSubscriptionKey = global.APIM_SUBSCRIPTION_KEY;
  }

  get baseUrl() {
    return global.BOOKING_ENGINE_VIA_APIM
      ? `${global.APIM_BASE_URL}${this.token ? 'member' : 'open'}/1.0/`
      : global.BOOKING_ENGINE_BASE_URL;
  }

  async call(method, endpoint, { params = {}, body = undefined, headers = {} } = {}) {
    const callWrapper = async token => {
      const url = `${this.baseUrl}${endpoint}${genQueryParams(params)}`;

      const init = {
        method,
        cache: 'no-store',
        headers: {
          'Device-Time': moment.utc().valueOf(),
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...headers,
        },
      };

      if (token) {
        init.headers.Authorization = `Bearer ${this.token}`;
      }

      if (this.apimEnabled) {
        init.headers['Ocp-Apim-Subscription-Key'] = this.apimSubscriptionKey;
        init.headers['X-Transaction-Id'] = uuid4();
      }

      if (['PUT', 'POST'].includes(method)) {
        init.body = body === undefined ? '' : JSON.stringify(body);
      }

      return fetch(url, init);
    };

    let response;
    try {
      response = await callWrapper(this.token);
    } catch (e) {
      this.logError(e);
      throw e;
    }

    if (response.status === 401 || response.status === 403) {
      const payload = await this.refresh();

      if (payload) {
        this.token = payload.access_token;
        response = await callWrapper(this.token);
      }
    }

    if (!response.ok) {
      const error = await response.json();
      this.logError(error);
      throw error;
    }

    return response.json();
  }

  async getGymClasses({ location, from, to }) {
    return this.call('GET', 'bookable_items/gym/', {
      params: {
        location,
        from_date: from,
        to_date: to,
      },
    });
  }

  async getGymClassCategories() {
    return this.call('GET', 'categories/gym/');
  }

  async bookGymClass({ reservationId }) {
    return this.call('POST', 'bookings/gym/', {
      body: {
        reservation: reservationId,
      },
    });
  }

  async myGymClasses({ past = false }) {
    return this.call('GET', 'bookings/gym/', {
      params: {
        past,
      },
    });
  }

  async getMyBookings({ past = false, page = 1 }) {
    return this.call('GET', 'bookings/', {
      params: {
        past,
        page,
      },
    });
  }

  async cancelGymClass({ reservationContactId }) {
    return this.call('PATCH', `bookings/gym/${reservationContactId}/cancel/`);
  }

  async gyms() {
    return this.call('GET', 'facilities/gym/');
  }

  async beautyGyms() {
    return this.call('GET', 'facilities/beauty/');
  }

  async serverTime() {
    return this.call('GET', 'locale/time/');
  }

  async gymBySiteId({ siteId }) {
    return this.call('GET', `facilities/gym/site_id/${siteId}/`);
  }

  async beautyProducts({ location }) {
    return this.call('GET', 'products/beauty/', {
      params: {
        location,
      },
    });
  }

  async validatePostalCode({ postalCode }) {
    return this.call('GET', 'validation/address/', {
      params: {
        postal_code: postalCode,
      },
    });
  }

  async getAddressList({ postcodeId }) {
    return this.call('GET', 'address/find/', {
      params: {
        postcode_id: postcodeId,
      },
    });
  }

  async getSpecificAddressDetails({ addressId }) {
    return this.call('GET', 'address/retrieve/', {
      params: {
        address_id: addressId,
      },
    });
  }

  async beautyProductAvailability({ location, product, from, to }) {
    return this.call('GET', `products/beauty/${product}/availability/`, {
      params: {
        location,
        from_date: from,
        to_date: to,
      },
    });
  }

  async bookBeautyProduct({ bookingProductId, staffId, resourceId, from, to }) {
    return this.call('POST', 'bookings/beauty/', {
      body: {
        staff: staffId,
        booking_product: bookingProductId,
        start_time: from,
        end_time: to,
        resource: resourceId,
      },
    });
  }

  async cancelBeautySession({ reservationId }) {
    return this.call('PATCH', `bookings/beauty/${reservationId}/cancel/`);
  }

  async getHMOTTimeslots({ location, from, to }) {
    return this.call('GET', 'bookable_items/hmot/', {
      params: {
        location,
        from_date: from,
        to_date: to,
      },
    });
  }

  async bookHMOTSession({ reservationId }) {
    return this.call('POST', 'bookings/hmot/', {
      body: {
        reservation: reservationId,
      },
    });
  }

  async cancelHMOTSession({ reservationContactId }) {
    return this.call('PATCH', `bookings/hmot/${reservationContactId}/cancel/`);
  }

  async getEventReservation(resource, reservation) {
    return this.call('GET', 'bookable_items/events/', {
      params: {
        resource,
        reservation,
      },
    });
  }

  async createEventReservation({
    reservation,
    firstName,
    lastName,
    email,
    postcode,
    guest,
    phoneNumber,
    smsService,
    emailService,
    phoneService,
    dateOfBirth,
  }) {
    return this.call('POST', 'bookings/events/', {
      body: {
        reservation,
        first_name: firstName,
        last_name: lastName,
        email,
        postcode,
        guest,
        phone_number: phoneNumber,
        sms_service: smsService,
        email_service: emailService,
        phone_service: phoneService,
        date_of_birth: dateOfBirth,
      },
    });
  }

  async cancelPTSession({ reservationContactId }) {
    return this.call('PATCH', `bookings/pt/${reservationContactId}/cancel/`);
  }

  async registerPushToken({ pushToken, type = 'expo', deviceId = null }) {
    return this.call('POST', 'profile/push-tokens/', {
      body: {
        push_token: pushToken,
        push_token_type: type,
        device_id: deviceId,
      },
    });
  }

  async getConsultantPricing({ gmcCode }) {
    return this.call('GET', `consultants/${gmcCode}/pricing/`);
  }

  consultantServiceSearch({ postcode }) {
    return this.call('GET', 'consultants/service-search/', {
      params: { postal_code: postcode },
    });
  }
}

export default BookingEngine;
