import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FlatList from './FlatList';
import DaysToggle from './DaysToggle';
import useBeautyProductTimeslots from '../hooks/useBeautyProductTimeslots';
import purifyObject from '../utils/purifyObject';
import TimeslotToggle from './TimeslotToggle';

import { ReactComponent as Close } from '../static/img/close.svg';
import { ReactComponent as Location } from '../static/img/location.svg';
import { ReactComponent as Pound } from '../static/img/pound.svg';
import { ReactComponent as Checkmark } from '../static/img/checkmark.svg';

import './BeautyProduct.sass';
import SelectBox from './SelectBox';
import CTAButton from './CTAButton';
import Modal from './Modal';

const Timeslots = ({ product, location }) => {
  const { days, timeslots, timeslotProducts, staffMembers } = useBeautyProductTimeslots(
    product,
    location,
  );
  const confirmModalRef = useRef();
  const history = useHistory();
  const [confirmed, setConfirmed] = useState(false);

  return (
    <>
      <Modal ref={confirmModalRef} onHide={() => setConfirmed(false)} type="beauty_confirm">
        {({ book, date, start, end, staff, resource }, hide) => (
          <>
            <div className="h1 mb-10">Complete your booking</div>
            <div className="mb-20">If you’re happy with everything, click ‘Book’.</div>
            <div className="h2 mb-10">{product.name}</div>
            <div className="details__wrapper">
              <table className="details__table">
                <tr>
                  <td>Time</td>
                  <td>
                    {start.format('HH:mm')} {date.format('dddd D MMMM')}
                  </td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>{`${resource.name} - ${resource.facility.name}`}</td>
                </tr>
                <tr>
                  <td>Therapist</td>
                  <td>{staff.name}</td>
                </tr>
              </table>
              <div className="prerequisites__wrapper">
                {product.prerequisite && (
                  <div className="mb-20">
                    <div className="wysiwyg">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: product.prerequisite,
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="custom-checkbox__wrapper toc__checkbox">
                  <input
                    type="checkbox"
                    className="custom-checkbox__value"
                    onChange={() => setConfirmed(!confirmed)}
                    id="toc"
                    checked={confirmed}
                  />
                  <label className="custom-checkbox__label" htmlFor="toc">
                    <span className="custom-checkbox__faux">
                      <Checkmark />
                    </span>
                    <span>{'I agree to the '}</span>
                    <a
                      target="_blank"
                      href="https://www.nuffieldhealth.com/terms/nuffield-health-website-terms-and-conditions"
                      rel="noreferrer"
                    >
                      terms and conditions
                    </a>
                    <sup>*</sup>
                  </label>
                </div>
                <CTAButton
                  action={{
                    name: 'Book',
                    type: 'Book',
                    call: book,
                  }}
                  onAction={() => {
                    history.push(
                      `/gyms/${location}/beauty/booked/`,
                      purifyObject({
                        date,
                        start,
                        end,
                        product,
                        staff,
                        resource,
                      }),
                    );
                  }}
                  onError={() => hide()}
                  disabled={!confirmed}
                />
              </div>
            </div>
          </>
        )}
      </Modal>
      <div className="therapist-filter__wrapper">
        <div className="therapist-filter__title">Therapist</div>
        <SelectBox title="Therapist" data={staffMembers} type="radio">
          <div className="custom-select__list">
            <FlatList
              data={staffMembers}
              renderItem={({ item: { sfid, name }, selected, select }, i) => (
                <div className="custom-select__li" key={i}>
                  <input
                    type="radio"
                    name="timeOfDay"
                    id={`staff-${sfid}`}
                    className="custom-radio__value"
                    checked={selected}
                    onChange={select}
                  />
                  <label className="custom-select__label" htmlFor={`staff-${sfid}`}>
                    {sfid === null ? 'All' : name}
                  </label>
                </div>
              )}
            />
          </div>
        </SelectBox>
      </div>

      <DaysToggle {...days} showMonth />
      {product.availabilities.loading ? (
        <p className="products-loading">Loading...</p>
      ) : (
        <>
          <TimeslotToggle
            timeslots={timeslots}
            renderEmpty={() => (
              <p className="no-staff-available">No available staff members for this timeframe</p>
            )}
          />
          <div className="bookable-products__list">
            <FlatList
              data={timeslotProducts}
              renderItem={timeslotProduct => {
                const { staff } = timeslotProduct;
                const { sfid, name, role, gender } = staff;
                return (
                  <div className="bookable-product" key={sfid}>
                    <div className="bold">{name}</div>
                    <button
                      className="cta"
                      onClick={() => confirmModalRef.current.show(timeslotProduct)}
                    >
                      Select
                    </button>
                  </div>
                );
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ({ product, location }) => {
  const { name, availabilities, price, action, facility } = product;
  const detailsModalRef = useRef();

  return (
    <>
      <Modal ref={detailsModalRef}>
        {({ description, prerequisite, name }) => (
          <>
            <div className="h1 mb-20">{name}</div>
            <p className="modal-text">{description}</p>
            {prerequisite && (
              <div className="mt-10">
                <div className="wysiwyg">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: prerequisite,
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </Modal>
      <div className="product">
        <div className="time-wrapper bold">
          {product.treatment_duration && <span>{`${product.treatment_duration} minutes`}</span>}
        </div>
        <div className="product-details">
          <div className="product-title bold">
            {name}
            <a
              className="link title-view-details__link desktop-only"
              onClick={() => detailsModalRef.current.show(product)}
            >
              View details
            </a>
          </div>
          <div className="detail">
            <span className="location-icon__wrapper">
              <Location />
            </span>
            <span className="location-name">{facility.name}</span>
          </div>
          <div className="detail">
            <span className="price-icon__wrapper">
              <Pound />
            </span>
            {price && <span>{`£${price} to be paid at appointment`}</span>}
          </div>
          <div />
        </div>
        <div className="cta-wrapper">
          <a
            className="link view-details__link mobile-only"
            onClick={() => detailsModalRef.current.show(product)}
          >
            View details
          </a>
          {action && (
            <button
              className={`cta ${action.name === 'Close' && 'm--close'}`}
              onClick={action.call}
            >
              {action.name}
            </button>
          )}
        </div>
      </div>
      {availabilities.visible && (
        <div className="availabilities-wrapper">
          <Timeslots product={product} location={location} availabilities={availabilities} />
        </div>
      )}
    </>
  );
};
