import createReducer from '../utils/createReducer';

const initialState = {
  gyms: [],
  beautyGyms: [],
};

export default createReducer('facilities', initialState, {
  addFacilities: (state, { service, items }) => ({
    ...state,
    [service]: items,
  }),
  addFacility: (state, { service, item }) => ({
    ...state,
    [service]: item,
  }),
});
