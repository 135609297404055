import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import moment from 'moment-timezone';
import useConsultantClinicDays from '../hooks/useConsultantBookings';
import DaysToggle from '../components/DaysToggleConsultants';
import TimeslotToggle from '../components/TimeslotToggle';
import FlatList from '../components/FlatList';
import purifyObject from '../utils/purifyObject';
import CTAButton from '../components/CTAButton';
import InitialBookingDisclaimer from '../components/InitialBookingDisclaimer';

import { ReactComponent as PersonPlus } from '../static/img/person-plus.svg';
import { ReactComponent as Location } from '../static/img/location.svg';
import { ReactComponent as Pound } from '../static/img/pound.svg';

import './Consultant.sass';

export default () => {
  const { gmcCode } = useParams();
  const { loaded, selectedSlots, bookableSlots, clinicDays, slots, hospitals } =
    useConsultantClinicDays(gmcCode);
  const history = useHistory();
  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [hideTimeslots, setHideTimeSlots] = useState();
  const [checkState, setCheckState] = useState(false);

  const iframeURL = window.location.origin;
  let parentURL = 'https://www.nuffieldhealth.com';
  if (iframeURL.includes('-preprod')) {
    parentURL = 'https://www.preprod.nuffieldhealth.dev';
  } else if (iframeURL.includes('-uat')) {
    parentURL = 'https://www01.uat.nuffieldhealth.dev';
  } else if (iframeURL.includes('-qa')) {
    parentURL = 'https://www01.qa.nuffieldhealth.dev';
  }
  useEffect(() => {
    function dataLayerEvent() {
      if (bookableSlots) {
        let specialtyName;
        for (let i = 0; i < slots.length; i++) {
          if (slots[i].item.timeslots.length !== 0) {
            specialtyName = slots[i].item.timeslots[0].item[1][0].specialty;
          }
        }
        let hospitalNames = hospitals[0][1].hospitalName;
        if (hospitals.length > 1) {
          for (let i = 1; i < hospitals.length; i++) {
            hospitalNames += ', ' + hospitals[i][1].hospitalName;
          }
        }
        window.parent.postMessage(
          {
            event_id: 'dataLayer',
            data: {
              event: 'consultantWidgetLoaded',
              consultant: {
                id: `${gmcCode}`,
                location: `${hospitalNames}`,
                specialty: `${specialtyName}`,
              },
            },
          },
          parentURL,
        );
      } else {
        window.parent.postMessage(
          {
            event_id: 'dataLayer',
            data: {
              event: 'noAppointments',
              consultant: {
                id: `${gmcCode}`,
              },
            },
          },
          parentURL,
        );
      }
    }
    if (loaded) {
      if (checkState) {
        dataLayerEvent();
      } else {
        // timeout is used here to allow the bookableSlots state to update based on the api response and to only fire one dataLayer event in this useEffect.
        setTimeout(() => {
          setCheckState(true);
        }, 1000);
      }
    }
  }, [loaded, bookableSlots, checkState, gmcCode, parentURL]);
  const timeSlotsForSelectedHospital = useMemo(() => {
    let filteredGroupedTimeslots = slots.map(timeslot => {
      for (let i = 0; i < timeslot.item.timeslots.length; i++) {
        const slotLocation = timeslot.item.timeslots[i].item[1][0].hospital.hospitalId;
        const selectedHospitalInTimeslot = selectedHospitals.some(location =>
          slotLocation.includes(location),
        );
        if (selectedHospitalInTimeslot) {
          timeslot.item.timeslots[i].filterValid = true;
        } else if (!selectedHospitalInTimeslot) {
          timeslot.item.timeslots[i].filterValid = false;
        }
      }
      return timeslot;
    });
    const filteredData = filteredGroupedTimeslots.map(obj => ({
      ...obj,
      item: {
        name: obj.item.name,
        timeslots: obj.item.timeslots.filter(timeslot => timeslot.filterValid === true),
      },
    }));
    return filteredData;
  }, [slots, selectedHospitals]);

  return loaded ? (
    <>
      <InitialBookingDisclaimer />
      <div className="m--consultant">
        {bookableSlots === true ? (
          <div>
            <div className="pattern" />
            <div className="header__wrapper mb-40">
              <div className="header">
                <div className="h1">Book consultant</div>
              </div>
            </div>
            <div className="datepicker">
              <DaysToggle
                days={clinicDays.selectableDays}
                selectedDay={clinicDays.selectedDay}
                actions={clinicDays.actions}
                showMonth
                hospitals={hospitals}
                selectedHospitals={selectedHospitals}
                setSelectedHospitals={setSelectedHospitals}
                setHideTimeSlots={setHideTimeSlots}
              />
              {!hideTimeslots && <TimeslotToggle timeslots={timeSlotsForSelectedHospital} />}
            </div>
            <FlatList
              data={selectedSlots}
              renderItem={slot => {
                const { slotId, slotTime, slotDuration, consultant, hospital, slotDate } = slot;
                const mSlotDay = moment(slotDate).format('dddd');
                const mSlotDate = moment(slotDate).format('Do MMMM');
                const mSlotTime = moment(slotTime, 'HH:mm');
                const mSlotEndTime = mSlotTime.clone().add(slotDuration, 'minutes');
                return (
                  <div key={slotId} className="card">
                    <div className="time">
                      {mSlotTime.format('HH:mm')}
                      {' - '}
                      {mSlotEndTime.format('HH:mm')}
                      <div className="slotDate">
                        <span>{mSlotDay}</span>
                        <span>{mSlotDate}</span>
                      </div>
                    </div>
                    <div className="details">
                      <div className="product-title bold">Consultation</div>
                      <div className="detail">
                        <div className="person-icon__wrapper">
                          <PersonPlus />
                        </div>
                        <div>{consultant.name}</div>
                      </div>
                      <div className="detail location__wrapper">
                        <span className="location-icon__wrapper">
                          <Location />
                        </span>
                        <div>{hospital.hospitalName}</div>
                      </div>
                      <div className="detail">
                        <span className="location-icon__wrapper" />
                        <div>
                          If we are unable to hold the appointment face to face, we will be in touch
                          to arrange a consultation over the phone
                        </div>
                      </div>
                      <div className="detail">
                        <span className="price-icon__wrapper">
                          <Pound />
                        </span>
                        <div>{`${consultant.price}`}</div>
                      </div>
                    </div>
                    <div className="cta__wrapper">
                      <CTAButton
                        action={{
                          name: 'Select',
                          call: async () => {
                            window.parent.postMessage(
                              {
                                event_id: 'dataLayer',
                                data: {
                                  event: 'consultantSelectBooking',
                                  consultant: {
                                    consultation_time: `${slotDate}, ${slotTime}`,
                                    id: `${gmcCode}`,
                                    location: `${slot.hospital.hospitalName}`,
                                    specialty: `${slot.specialty}`,
                                  },
                                },
                              },
                              parentURL,
                            );
                            history.push(`/consultants/${gmcCode}/details/`, purifyObject(slot));
                          },
                          type: 'BOOK',
                        }}
                      />
                    </div>
                  </div>
                );
              }}
            />
          </div>
        ) : (
          <div className="m--consultant">
            <div className="pattern" />
            <div className="header__wrapper mb-40">
              <div className="header">
                <div className="h1">
                  Sorry, there are no appointments available online for the next 90 days. Please
                  choose another consultant or call 0300 123 1903
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  ) : (
    <p>Loading...</p>
  );
};
