import { useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import { orderBy } from 'lodash';
import useBookingEngine from '../useBookingEngine';
import beautyActions from '../../actions/beautyActions';
import getLoggedInAction from './getLoggedInAction';
import getLoggedOutAction from './getLoggedOutAction';
import useAuthentication from '../useAuthentication';

export default location => {
  const { login, access_token: accessToken } = useAuthentication();
  const bookingEngine = useBookingEngine();
  const beautyProducts = useSelector(state => state.beautyProducts);
  const products = useMemo(() => beautyProducts[location] || {}, [beautyProducts, location]);
  const { fetchBeautyProducts, closeAvailabilities, openAvailabilities } =
    beautyActions(bookingEngine);

  useEffect(() => {
    if (location) fetchBeautyProducts(location, true);
  }, [location]);

  const productList = useMemo(
    () =>
      orderBy(Object.values(products), ['treatment_type', 'name']).map(product => ({
        ...product,
        treatment_type: product.treatment_type || 'Other',
        type: 'BEAUTY_PRODUCT',
        action: (() =>
          accessToken
            ? getLoggedInAction(product, location, { closeAvailabilities, openAvailabilities })
            : getLoggedOutAction({ login }))(),
      })),
    [products, accessToken],
  );

  const productsByTreatmentType = useMemo(
    () =>
      productList.reduce(
        (accumulator, product) => ({
          ...accumulator,
          [product.treatment_type]: [...(accumulator[product.treatment_type] || []), product],
        }),
        {},
      ),
    [productList],
  );

  const reload = useCallback(async () => {
    if (location) await fetchBeautyProducts(location, true);
  }, [location]);

  const productsByType = useMemo(() => Object.entries(productsByTreatmentType), [products]);

  return {
    productsByType,
    reload,
  };
};
