import React, { Component } from 'react';
import { v4 as uuid4 } from 'uuid';
import genQueryParams from '../utils/genQueryParams';
import PhoneIcon from '../static/img/phoneIcon.png';
import { genderIdentity } from '../enums/genderIdentity';

class HealthAssessmentBookings {
  static getInstance(accessToken, refresh = async () => {}, logError = async () => {}) {
    if (this.instance === undefined) {
      this.instance = new this();
    }

    this.instance.logError = logError;
    this.instance.refresh = refresh;
    this.instance.token = accessToken;
    return this.instance;
  }

  constructor() {
    this.apimEnabled = global.BOOKING_ENGINE_VIA_APIM;
    this.apimSubscriptionKey = global.APIM_HA_SUBSCRIPTION_KEY;
    this.baseUrl = `${global.APIM_BASE_URL.slice(0, -'booking/'.length)}health-assessment/1.0/`;
  }

  async call(method, endpoint, { params = {}, body = undefined, headers = {} } = {}) {
    let response = null;
    const callWrapper = async token => {
      const url = `${this.baseUrl}${endpoint}${genQueryParams(params)}`;
      const init = {
        method,
        cache: 'no-store',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...headers,
        },
      };
      if (token) {
        init.headers.Authorization = `Bearer ${this.token}`;
      }

      if (this.apimEnabled) {
        init.headers['Ocp-Apim-Subscription-Key'] = this.apimSubscriptionKey;
        init.headers['X-Transaction-Id'] = uuid4();
      }

      if (['PUT', 'POST'].includes(method)) {
        init.body = body === undefined ? '' : JSON.stringify(body);
      }

      return fetch(url, init);
    };

    try {
      response = await callWrapper(this.token);
      if (response.status === 401 || response.status === 403) {
        const payload = await this.refresh();

        if (payload) {
          this.token = payload.access_token;
          response = await callWrapper(this.token);
        }
      }
      if (!response.ok) {
        const error = await response.json();
        throw error;
      }
    } catch (e) {
      const errorMessage = (
        <div>
          Please refresh the page. If the problem persists, please contact our Customer Centre team
          for help using one of the numbers below.
          <br />
          <br />
          <div className="error-msg-phone">
            <div className="error-msg-phone-box">
              If you&#39;re trying to book an exercise class
              <br />
              <br />
              <div className="error-call-number-box">
                <img className="error-phone-icon" src={PhoneIcon} alt="call" />
                <p className="error-phone-number">0300 123 5084</p>
              </div>
            </div>
            <div className="error-msg-phone-box">
              If you&#39;re trying to book a Health Assessment
              <br />
              <br />
              <div className="error-call-number-box">
                <img className="error-phone-icon" src={PhoneIcon} alt="call" />
                <p className="error-phone-number">0300 123 1844</p>
              </div>
            </div>
          </div>
          <br />
          Our lines are open 8am–8pm, Monday to Friday. We&#39;ll aim to answer your call quickly,
          but our wait times often vary during peak hours.
          <br />
          <br />
        </div>
      );

      this.logError({
        error: 'CUSTOM',
        text: errorMessage,
      });
      throw e;
    }

    return response.json();
  }

  async getEligibility({ idaasId, payor }) {
    return this.call('GET', `GetEligibility/ha-packages/idaas-id/${idaasId}`, {
      params: {
        payor,
      },
    });
  }

  async getLocations(parameters) {
    return this.call('GET', `Appointment/locations`, {
      params: parameters,
    });
  }

  async getSlots(parameters) {
    return this.call('GET', `Appointment/slots`, {
      params: parameters,
    });
  }

  async postAppointment(body) {
    return this.call('POST', `Appointment/create`, {
      body,
    });
  }

  async putAppointment({ appointmentId, status, specialRequirements }) {
    let extensions = [
      {
        url: 'http://fhir.lumeon.com/dstu2/appointment/email/confirmation',
        valueBoolean: true,
      },
    ];

    // Add waive cancellation charge FHIR extension if a cancelled appointment.
    if (status === 'cancelled') {
      extensions.push({
        url: 'http://fhir.lumeon.com/dstu2/appointment/waive-cancel-charges',
        valueBoolean: true,
      });
    }

    return this.call('PUT', `Appointment/${appointmentId}`, {
      body: {
        extension: extensions,
        resourceType: 'Appointment',
        id: appointmentId,
        status: status,
        comment: specialRequirements,
      },
    });
  }

  async getHaBookings({ idaasId, appointment_filter }) {
    return this.call('GET', `Appointment/vita/idaas-id/${idaasId}`, {
      params: {
        appointment_filter,
      },
    });
  }

  async getPatient(patientId) {
    return this.call('GET', `Patient/${patientId}`, {});
  }

  async putPatientGenderInfo({
    patientId,
    gender,
    sexAssignedAtBirth
  }) {
    const code = genderIdentity[gender];

    return this.call('PUT', `Patient/${patientId}`, {
      body: {
        resourceType: 'Patient',
        id: patientId,
        gender: sexAssignedAtBirth,
        extension: [
          {
            url: 'http://fhir.lumeon.com/dstu2/patient/gender-identity',
            valueCoding: {
              system: 'http://fhir.lumeon.com/dstu2/gender-identity',
              code,
            },
          }
        ],
      },
    });
  }

  async putPatient({
    patientId,
    mobilePhone,
    daytimePhone,
    addressLookup,
    practitionerGenderPreference,
  }) {
    const telecom = mobilePhone
      ? [{ use: 'mobile', system: 'phone', value: mobilePhone }]
      : [{ use: 'work', system: 'phone', value: daytimePhone }];
    const address = [
      {
        use: 'home',
        line: [
          addressLookup?.line_one,
          addressLookup?.line_two,
          addressLookup?.line_three,
          addressLookup?.line_four,
          addressLookup?.line_five,
        ].filter(Boolean),
        city: addressLookup?.city,
        postalCode: addressLookup?.postal_code,
        state: addressLookup?.county,
        country: addressLookup?.country,
      },
    ];

    return this.call('PUT', `Patient/${patientId}`, {
      body: {
        resourceType: 'Patient',
        id: patientId,
        telecom,
        address,
        extension: [
          {
            url: 'http://fhir.lumeon.com/dstu2/patient/preferred-practitioner-gender',
            valueString: practitionerGenderPreference,
          },
        ],
      },
    });
  }
}

export default HealthAssessmentBookings;
