import { useState, useEffect, useRef } from 'react';
import { ScriptStates } from '../enums/booking';

/**
 * {@link https://github.com/uidotdev/usehooks/blob/main/index.js GitHub}.
 */
export const useScript = (src, options = {}) => {
  const [status, setStatus] = useState(ScriptStates.Loading);
  const optionsRef = useRef(options);

  useEffect(() => {
    let script = document.querySelector(`script[src="${src}"]`);

    const domStatus = script?.getAttribute('data-status');
    if (domStatus) {
      setStatus(domStatus);
      return;
    }

    if (script === null) {
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.setAttribute('data-status', ScriptStates.Loading);
      document.body.appendChild(script);

      const handleScriptLoad = () => {
        script.setAttribute('data-status', ScriptStates.Ready);
        setStatus(ScriptStates.Ready);
        removeEventListeners();
      };

      const handleScriptError = () => {
        script.setAttribute('data-status', ScriptStates.Error);
        setStatus(ScriptStates.Error);
        removeEventListeners();
      };

      const removeEventListeners = () => {
        script.removeEventListener('load', handleScriptLoad);
        script.removeEventListener('error', handleScriptError);
      };

      script.addEventListener('load', handleScriptLoad);
      script.addEventListener('error', handleScriptError);

      const removeOnUnmount = optionsRef.current.removeOnUnmount;

      return () => {
        if (removeOnUnmount === true) {
          script.remove();
          removeEventListeners();
        }
      };
    } else {
      setStatus(ScriptStates.Unknown);
    }
  }, [src]);

  return status;
};
