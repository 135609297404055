import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ArrowAlt } from '../static/img/arrow-alt.svg';
import CTAButton from './CTAButton';

import './BackBtn.sass';

export default btn => {
  const history = useHistory();

  return (
    <div className="back-btn-container">
      <ArrowAlt />
      <CTAButton
        className="tertiary-btn"
        action={{
          name: `${btn.name}`,
          type: 'BACK',
          call: async () => {
            history.goBack();
          },
        }}
      />
    </div>
  );
};
