import createReducer from '../utils/createReducer';

const defaultState = {
  selected_event: null,
  reservation: null,
  items: {},
};

const addEvents = (state, { reservation, items }) => ({
  ...state,
  reservation,
  items: (() =>
    items.reduce((accumulator, item) => {
      const newState = { ...accumulator };
      if (!(item.sfid in newState)) {
        newState[item.sfid] = item;
      }
      return newState;
    }, state.items))(),
});

const selectEvent = (state, { reservationId }) => ({ ...state, selected_event: reservationId });

export default createReducer('events', defaultState, {
  addEvents,
  selectEvent,
});
