export const MORNING = {
  name: 'Morning',
  from: '06:00',
  to: '12:00',
};

export const MORNING_FULL = {
  name: 'Morning',
  from: '00:00',
  to: '12:00',
};

export const AFTERNOON = {
  name: 'Afternoon',
  from: '12:00',
  to: '17:00',
};

export const EVENING = {
  name: 'Evening',
  from: '17:00',
  to: '22:00',
};

export const EVENING_FULL = {
  name: 'Evening',
  from: '17:00',
  to: '24:00',
};

export const ANYTIME = {
  name: 'Any Time',
  from: MORNING.from,
  to: EVENING.to,
};
