import moment from 'moment-timezone';

export default date => {
  const mDate = moment(`${date.year}-${date.month}-${date.day}`);
  const dobDate = new Date(date.year, date.month - 1, date.day);
  const todayDate = new Date();
  if (
    mDate.isValid() &&
    date.day <= 31 &&
    date.day != 0 &&
    date.month <= 12 &&
    date.month != 0 &&
    date.year >= 1900 &&
    dobDate < todayDate
  ) {
    return true;
  } else {
    return false;
  }
};
