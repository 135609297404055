import createReducer from '../utils/createReducer';

const INITIAL_STATE = {
  clinicDays: [],
  slots: [],
  hospitals: {},
  consultants: {},
};

export default createReducer('consultants', INITIAL_STATE, {
  setHospitals: (state, hospitals) => ({
    ...state,
    hospitals: {
      ...state.hospitals,
      ...hospitals,
    },
  }),
  setConsultants: (state, consultants) => ({
    ...state,
    consultants: {
      ...state.consultants,
      ...consultants,
    },
  }),
  setClinicDays: (state, clinicDays) => ({ ...state, clinicDays }),
  setSlots: (state, slots) => ({ ...state, slots }),
});
