import React from 'react';
import useMyBookings from '../hooks/useMyBookings';
import GymClassList from '../components/GymClassList';
import FlatList from '../components/FlatList';

import './MyBookings.sass';
import CTAButton from '../components/CTAButton';
import ClassStatus from '../components/ClassStatus';

export default () => {
  const { myBookings, historicActiveToggle, pastBookings, loadMore } = useMyBookings({
    groupByDay: false,
  });

  return (
    <>
      <div className={`page ${pastBookings && 'm--past-bookings'}`}>
        <div className="tabs__wrapper">
          <FlatList
            data={historicActiveToggle}
            renderItem={({ item: { name }, select, selected }, index) => (
              <button
                key={index}
                className={`tab__item ${selected ? 'm--active' : ''}`}
                onClick={select}
              >
                {name}
              </button>
            )}
          />
        </div>
        <span className="divider" />

        <GymClassList
          gymClasses={myBookings}
          hideOverlay={!!pastBookings}
          showDate
          reservationDetails
          renderDetails={item => <ClassStatus item={item} />}
        />
        {pastBookings && loadMore.call && <CTAButton action={loadMore} />}
      </div>
    </>
  );
};
