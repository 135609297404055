import useActions from '../hooks/useActions';
import gymClasses from '../reducers/gymClasses';
import facilities from '../reducers/facilities';
import gymClassCategories from '../reducers/gymClassCategories';

const addBooking = booking => ({
  type: gymClasses.types.addBooking,
  payload: booking,
});

const setStatus = booking => ({
  type: gymClasses.types.setStatus,
  payload: booking,
});

const refresh = bookingId => ({
  type: gymClasses.types.doRefresh,
  payload: { bookingId },
});

export default bookingEngine =>
  useActions({
    fetchForLocation: (location, daysToLoad) => async dispatch => {
      if (location === null || daysToLoad.length === 0) {
        return;
      }

      const { items } = await bookingEngine.getGymClasses({
        location,
        from: daysToLoad[0].startOf('day').toISOString(true),
        to: daysToLoad[daysToLoad.length - 1].endOf('day').toISOString(true),
      });

      await dispatch({
        type: gymClasses.types.addBookableItems,
        payload: {
          gymClasses: items,
          fromDate: daysToLoad[0].clone().startOf('day'),
          toDate: daysToLoad[daysToLoad.length - 1].clone().endOf('day'),
        },
      });
    },

    fetchMyBookings:
      (past = false, page = 1) =>
      async dispatch => {
        const { items } = await bookingEngine.myGymClasses({ past });

        dispatch({
          type: gymClasses.types.addBookings,
          payload: items,
        });
      },

    book: reservationId => async dispatch => {
      try {
        const booking = await bookingEngine.bookGymClass({
          reservationId,
        });

        dispatch(
          addBooking({
            updateCount: true,
            count: '+attendees',
            ...booking,
          }),
        );

        return booking;
      } catch (e) {
        dispatch(refresh(reservationId));
        throw e;
      }
    },

    cancel: (reservationId, reservationContactId) => async dispatch => {
      try {
        const booking = await bookingEngine.cancelGymClass({
          reservationContactId,
        });

        dispatch(
          addBooking({
            updateCount: true,
            count: '-attendees',
            ...booking,
          }),
        );
      } catch (e) {
        dispatch(refresh(reservationId));
        throw e;
      }
    },

    bookToWaitlist: reservationId => async dispatch => {
      try {
        const booking = await bookingEngine.bookGymClass({
          reservationId,
        });

        dispatch(
          addBooking({
            updateCount: true,
            count: '+members_on_waiting_list',
            ...booking,
          }),
        );

        return booking;
      } catch (e) {
        dispatch(refresh(reservationId));
        throw e;
      }
    },

    removeFromWaitlist: (reservationId, reservationContactId) => async dispatch => {
      try {
        const booking = await bookingEngine.cancelGymClass({
          reservationContactId,
        });

        dispatch(
          addBooking({
            updateCount: true,
            count: '-members_on_waiting_list',
            ...booking,
          }),
        );
      } catch (e) {
        dispatch(refresh(reservationId));
        throw e;
      }
    },

    fetchGyms: () => async dispatch => {
      const { items } = await bookingEngine.gyms();

      await dispatch({
        type: facilities.types.addFacilities,
        payload: {
          service: 'gyms',
          items,
        },
      });
    },

    fetchBeautyGyms: () => async dispatch => {
      const { items } = await bookingEngine.beautyGyms();

      await dispatch({
        type: facilities.types.addFacilities,
        payload: {
          service: 'beautyGyms',
          items,
        },
      });
    },

    fetchGymBySiteId: siteId => async dispatch => {
      const item = await bookingEngine.gymBySiteId({
        siteId,
      });

      await dispatch({
        type: facilities.types.addFacility,
        payload: {
          service: siteId,
          item,
        },
      });
    },

    fetchCategories: () => async dispatch => {
      const { items } = await bookingEngine.getGymClassCategories();

      dispatch({
        type: gymClassCategories.types.set,
        payload: items,
      });
    },
  });
