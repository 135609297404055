import { useEffect } from 'react';
import { useHistory } from 'react-router';
import OIDCB2CAuthentication from '../services/OIDCB2CAuthentication';

export default () => {
  const redirectUrl = sessionStorage.getItem('redirectUrl');
  const history = useHistory();

  const callback = async () => {
    const auth = new OIDCB2CAuthentication();
    const response = await auth.callback();

    if (response && response.returnUrl) {
      history.replace(response.returnUrl);
    }
  };

  useEffect(() => {
    callback(redirectUrl);
  }, []);

  return null;
};
