import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import useConsultantBookingsApi from '../useConsultantBookingsApi';
import consultantActions from '../../actions/consultantActions';
import useDays from '../useDays';
import useGroupedTimeslots from '../useGroupedTimeslots';
import useBookingEngine from '../useBookingEngine';

export default gmcCode => {
  const consultantBookings = useConsultantBookingsApi();
  const bookingEngine = useBookingEngine();
  const { fetchConsultantClinicDays, fetchConsultantSlots, clearConsultantSlots } =
    consultantActions(consultantBookings, bookingEngine);
  const { clinicDays, slots, hospitals, consultants } = useSelector(
    ({ consultants }) => consultants,
  );
  const clinicDaysMap = useMemo(
    () =>
      clinicDays.reduce(
        (days, { date, ...day }) => ({
          ...days,
          [date]: day,
        }),
        {},
      ),
    [clinicDays],
  );

  const nextBusinessDay = useMemo(() => {
    const tomorrow = moment().startOf('day').add(1, 'day');

    let increment;
    switch (tomorrow.day()) {
      case 6:
        increment = 3;
        break;
      case 0:
        increment = 2;
        break;
      default:
        increment = 1;
    }

    return tomorrow.add(increment, 'days');
  }, []);

  const [selectedDay, selectableDays, actions, setFirstDay] = useDays(9, {
    startDate: nextBusinessDay,
    endDate: moment().startOf('day').add(90, 'days'),
    extraData: date => ({
      clinic: clinicDaysMap[date.format('YYYY-MM-DD')],
    }),
    isDisabled: ({ clinic }) => !clinic,
    dependencies: [gmcCode],
  });

  useEffect(() => {
    setFirstDay(
      moment(
        Object.keys(clinicDaysMap).find(element => moment(element).diff(nextBusinessDay) >= 0),
      ),
    );
  }, [clinicDaysMap]);

  const [loaded, setLoaded] = useState(false);
  const [bookableSlots, setBookableSlots] = useState(false);
  useEffect(() => {
    fetchConsultantClinicDays({
      gmcCode,
      span: 90,
      fromDate: nextBusinessDay.format('YYYY-MM-DD'),
    }).then(() => {
      setLoaded(true);
    });
  }, [gmcCode, nextBusinessDay]);

  useEffect(() => {
    if (clinicDays.length == 0) {
      setBookableSlots(false);
    } else if (clinicDays.length >= 1) {
      setBookableSlots(true);
    }
    if (selectedDay && selectedDay.clinic) {
      const { clinic, date } = selectedDay;
      fetchConsultantSlots({
        gmcCode,
        fromDate: date.format('YYYY-MM-DD'),
        locations: clinic.locations,
      });
    } else {
      clearConsultantSlots();
    }
  }, [selectedDay, gmcCode]);

  const timeslots = useMemo(
    () =>
      slots.reduce((_timeslots, { slotTime, hospitalId, ...timeslot }) => {
        const newTimeslots = { ..._timeslots };
        const mSlotTime = moment(slotTime, 'HH.mm');
        const slot = mSlotTime.format('HH:mm');

        if (!newTimeslots.hasOwnProperty(slot)) {
          newTimeslots[slot] = [];
        }

        newTimeslots[slot].push({
          consultant: {
            gmcCode,
            ...consultants[gmcCode],
          },
          hospital: {
            hospitalId,
            ...hospitals[hospitalId],
          },
          slotTime,
          ...timeslot,
          type: 'CONSULTANT',
        });

        return newTimeslots;
      }, {}),
    [slots, consultants, hospitals],
  );

  const sortedTimeslots = useMemo(() => Object.entries(timeslots).sort(), [timeslots]);
  const [selectedSlot, groupedTimeslots] = useGroupedTimeslots(sortedTimeslots);

  return {
    loaded,
    bookableSlots,
    slots: groupedTimeslots,
    selectedSlots: selectedSlot ? selectedSlot[1] : [],
    clinicDays: {
      selectedDay,
      selectableDays,
      actions,
    },
    hospitals: Object.entries(hospitals),
  };
};
