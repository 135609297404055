import React, { useCallback, useState } from 'react';
import moment from 'moment-timezone';
import useForm from '../useForm';
import consultantActions from '../../actions/consultantActions';
import useConsultantBookingsApi from '../useConsultantBookingsApi';
import { VALIDATORS } from '../../enums/validators';
import validatePhoneNumber from '../../utils/validatePhoneNumber';
import { INSURERS } from '../../enums/insurers';
import validateName from '../../utils/validateName';
import validateEmail from '../../utils/validateEmail';
import validateAuthCode from '../../utils/validateAuthCode';
import validateDateOfBirth from '../../utils/validateDateOfBirth';

const DETAILS_FORM = {
  title: {
    type: 'select',
    label: 'Title',
    required: true,
    choices: [
      ['Captain', 'Captain'],
      ['Dr', 'Dr.'],
      ['Lady', 'Lady'],
      ['Lord', 'Lord'],
      ['Master', 'Master'],
      ['Miss', 'Miss'],
      ['Mr', 'Mr'],
      ['Mrs', 'Mrs'],
      ['Ms', 'Ms'],
      ['Prof.', 'Prof.'],
      ['Rev.', 'Rev.'],
      ['Other', 'Other'],
    ],
    placeholder: 'Please select your title',
    defaultValue: '',
  },
  first_name: {
    type: 'text',
    label: 'First name',
    required: true,
    validator: VALIDATORS.custom,
    isValid: value => validateName(value),
  },
  last_name: {
    type: 'text',
    label: 'Last name',
    required: true,
    validator: VALIDATORS.custom,
    isValid: value => validateName(value),
  },
  email: {
    type: 'email',
    label: 'Email',
    required: true,
    validator: VALIDATORS.custom,
    isValid: value => validateEmail(value),
  },
  gender: {
    type: 'select',
    label: 'Gender',
    required: true,
    choices: [
      ['female', 'Female'],
      ['male', 'Male'],
      ['other', 'Other / Prefer not to say'],
    ],
    placeholder: 'Please select your gender',
    defaultValue: '',
  },
  date_of_birth: {
    type: 'custom_date',
    label: 'Date of birth',
    required: true,
    validator: VALIDATORS.custom,
    isValid: date => validateDateOfBirth(date),
    defaultValue: {
      day: undefined,
      month: undefined,
      year: undefined,
    },
  },
  phone_number: {
    type: 'tel',
    label: 'Phone number',
    required: true,
    validator: VALIDATORS.custom,
    isValid: value => Boolean(value) && validatePhoneNumber(value) && value.match(/^0\d+$/),
    helperText:
      'Please enter a valid UK phone number. We will only contact you about your appointment.',
  },
  has_gp_referral: {
    type: 'radiogroup',
    label: 'Do you have a GP referral?',
    required: true,
    choices: [
      ['yes', 'Yes'],
      ['no', 'No'],
    ],
  },
  referral_details: {
    type: 'textarea',
    maxlength: 150,
    label: () => (
      <>
        Please provide your GP&apos;s details (name, address and telephone number). If you&apos;re
        not sure of these details, you can use the
        <a
          href="https://www.nhs.uk/service-search/find-a-GP/"
          target="_blank"
          rel="noreferrer noopener"
        >
          NHS GP Finder
        </a>
        . If you aren&apos;t registered with a GP, please tell us below.
      </>
    ),
    required: false,
  },
  send_discharge_details_to_gp: {
    type: 'radiogroup',
    label: 'Do you have an NHS GP?',
    required: true,
    choices: [
      ['yes', 'Yes'],
      ['no', 'No'],
    ],
  },
  gp_details: {
    type: 'gplookup',
    required: false,
    validator: VALIDATORS.custom,
    defaultValue: '',
  },
  addresslookup: {
    type: 'addresslookup',
    label: 'Find your address',
    required: true,
    validator: VALIDATORS.custom,
    isValid: value => {
      // Check if 'value' and its properties exist before using .trim()
      return (
        value &&
        value.line_one &&
        value.line_one.trim() !== '' &&
        value.city &&
        value.city.trim() !== '' &&
        value.postal_code &&
        value.postal_code.trim() !== ''
      );
    },
  },
  payment: {
    type: 'radiogroup',
    label: 'How will you pay for the consultation?',
    required: true,
    choices: [
      ['cash', 'By cash or card'],
      ['insurance', 'I have medical insurance'],
    ],
  },
  insurer: {
    type: 'select',
    label: 'Insurer',
    required: true,
    validator: VALIDATORS.custom,
    // Other insurer option hidden until this field is available in TrakCare
    // otherOption: true,
    isValid: (insurer, { payment }) => {
      if (payment === 'insurance') {
        return Boolean(insurer);
      }
      return true;
    },
    choices: INSURERS,
    placeholder: 'Please select your insurer',
    defaultValue: '',
  },
  insurer_name: {
    type: 'text',
    label: 'Please specify insurer',
    required: true,
    validator: VALIDATORS.custom,
    isValid: (insurerName, { payment, insurer }) => {
      if (payment === 'insurance' && insurer === 'other') {
        return Boolean(insurerName);
      }
      return true;
    },
  },
  policy_number: {
    type: 'text',
    label: 'Policy number',
    required: true,
    validator: VALIDATORS.custom,
    isValid: (policyNumber, { payment }) => {
      if (payment === 'insurance') {
        return Boolean(policyNumber);
      }
      return true;
    },
  },
  authorization_code: {
    type: 'text',
    label: 'Authorisation code',
    validator: VALIDATORS.custom,
    isValid: authCode => validateAuthCode(authCode),
  },
  communication_preferences: {
    type: 'checkboxgroup',
    defaultValue: {
      email: false,
      sms: false,
      phone: false,
    },
    choices: [
      ['email', 'Email'],
      ['sms', 'SMS'],
      ['phone', 'Phone'],
    ],
  },
};

const boolToBinaryString = value => Number(value).toString();
const gpDetailsToNotes = details => {
  if (details.freeText) {
    return `GP Details: ${details.freeText}`;
  }
  if (details.description) {
    return `GP Details: ${details.description} (${details.address}, ${details.postcode})`;
  }
  return 'GP Details: Online Booking - Please contact patient to confirm details';
};
const gpUnknownReference = 'G9999998/V81999';
const gpDetailsToReference = details => {
  if (details.code && !details.freeText) {
    return details.code;
  }
  return gpUnknownReference;
};

const removeTrakcareProblemCharacters = (string, char, replaceChar = '') =>
  string.replaceAll(char, replaceChar);

export default ({ slotId, consultant, hospital }) => {
  const consultantBookings = useConsultantBookingsApi();
  const { bookConsultantSlot } = consultantActions(consultantBookings);

  const [terms, setTerms] = useState(false);
  const toggleTerms = useCallback(() => setTerms(!terms), [terms, setTerms]);

  const detailsForm = useForm(DETAILS_FORM);

  const formComplete =
    Object.values(detailsForm).reduce(
      (valid, field) => valid && (field.touched ? field.valid : field.isValid()),
      true,
    ) &&
    Boolean(detailsForm.first_name.value) &&
    Boolean(detailsForm.last_name.value) &&
    Boolean(detailsForm.addresslookup.value.line_one) &&
    Boolean(detailsForm.addresslookup.value.city) &&
    Boolean(detailsForm.addresslookup.value.postal_code);

  const action = {
    call:
      formComplete && terms
        ? async () =>
            bookConsultantSlot({
              slotId,
              gmcCode: consultant.gmcCode,
              title: detailsForm.title.value,
              firstName: detailsForm.first_name.value,
              lastName: detailsForm.last_name.value,
              dateOfBirth: moment({
                ...detailsForm.date_of_birth.value,
                month: detailsForm.date_of_birth.value.month - 1, // Months are zero-indexed
              }).format('YYYY-MM-DD'),
              telephone: detailsForm.phone_number.value,
              address1: removeTrakcareProblemCharacters(
                detailsForm.addresslookup.value.line_one,
                ',',
              ),
              address2: removeTrakcareProblemCharacters(
                detailsForm.addresslookup.value.line_two,
                ',',
              ),
              townCity: removeTrakcareProblemCharacters(detailsForm.addresslookup.value.city, ','),
              postCode: detailsForm.addresslookup.value.postal_code,
              email: detailsForm.email.value,
              preAuthorizationNumber: detailsForm.authorization_code.value,
              memberId: detailsForm.policy_number.value,
              gpDetails: {
                gpReference: detailsForm.send_discharge_details_to_gp.value
                  ? gpDetailsToReference(detailsForm.gp_details.value)
                  : gpUnknownReference,
                gpNotes:
                  detailsForm.send_discharge_details_to_gp.value === 'yes'
                    ? removeTrakcareProblemCharacters(
                        gpDetailsToNotes(detailsForm.gp_details.value),
                        '/',
                        ' ',
                      )
                    : 'GP Details: Online Booking - Patient does not have an NHS GP',
                gpReferral: boolToBinaryString(detailsForm.has_gp_referral.value === 'yes'),
              },
              gender: detailsForm.gender.value,
              // If the payment method is insurance submit Self Pay to TrakCare
              insuranceProvider:
                detailsForm.payment.value === 'insurance' ? detailsForm.insurer.value : 'Self Pay',
              insuranceProviderFreeText:
                detailsForm.insurer.value === 'other' ? detailsForm.insurer_name.value : '',
              hospitalList: hospital.hospitalCode,
              marketingPrefs: {
                emailOptIn: boolToBinaryString(detailsForm.communication_preferences.value.email),
                phoneOptIn: boolToBinaryString(detailsForm.communication_preferences.value.phone),
                textOptIn: boolToBinaryString(detailsForm.communication_preferences.value.sms),
              },
              insurancePayment: boolToBinaryString(detailsForm.payment.value === 'insurance'),
            })
        : null,
    type: 'CONSULTANT',
    name: 'Book',
  };

  return {
    detailsForm,
    toggleTerms,
    action,
  };
};
