import { useMemo } from 'react';
import { uniqBy } from 'lodash';
import useMultiSelect from '../useMultiSelect';

export default bookableItems => {
  const practitioners = useMemo(
    () =>
      uniqBy(
        bookableItems
          .filter(({ practitioner }) => practitioner !== undefined && practitioner !== null)
          .map(({ practitioner }) => ({
            ...practitioner,
          })),
        'sfid',
      ),
    [bookableItems],
  );

  return useMultiSelect(practitioners, 'sfid', false);
};
