export default ({ login }) => {
  const type = null;
  const name = 'Log in';

  const call = async () => {
    await login();
    return null;
  };

  return {
    call,
    name,
    type,
  };
};
