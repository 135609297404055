import React, { createContext, useEffect, useMemo, useState, useContext } from 'react';

const ParentWindowContext = createContext({});

const ParentWindowProvider = ({ children }) => {
  const [parentWindowInfo, setParentWindowInfo] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    window.iFrameResizer = {
      onReady: () => setLoaded(true),
    };
  }, []);

  useEffect(() => {
    if (loaded) {
      window.parentIFrame.getPageInfo(setParentWindowInfo);
    }
  }, [loaded]);

  const provider = useMemo(() => ({ parentWindowInfo, setParentWindowInfo }), [parentWindowInfo]);
  return <ParentWindowContext.Provider value={provider}>{children}</ParentWindowContext.Provider>;
};

function useParentWindowCtx() {
  const context = useContext(ParentWindowContext);
  if (context === undefined) {
    throw new Error('useParentWindowCtx must be used within a ParentWindowProvider');
  }
  return context;
}
export { ParentWindowProvider, useParentWindowCtx };
