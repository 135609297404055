import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import { range } from 'lodash';
import useDays from '../useDays';
import useBookingEngine from '../useBookingEngine';
import beautyActions from '../../actions/beautyActions';
import useSelect from '../useSelect';
import concatDateTime from '../../utils/concatDateTime';
import useAuthentication from '../useAuthentication';
import useGroupedTimeslots from '../useGroupedTimeslots';

export default (product, location) => {
  const { access_token: accessToken } = useAuthentication();
  const bookingEngine = useBookingEngine();

  const staffMembers = useMemo(
    () => [
      {
        sfid: null,
        name: 'Choose therapist',
      },
      ...product.availabilities.items.map(({ staff }) => staff),
    ],
    [product],
  );

  const [selectedStaffMember, selectableStaffMembers] = useSelect(staffMembers);

  // We filter out all available days in from all the availabilities
  const daysAvailable = useMemo(() => {
    if (product.availabilities.items.length > 0) {
      return product.availabilities.items.reduce(
        (dates, { timeslots: _timeslots }) => ({
          ...dates,
          ..._timeslots.reduce(
            (timeslotDates, { slotDate }) => ({
              ...timeslotDates,
              [slotDate]: null,
            }),
            {},
          ),
        }),
        {},
      );
    }
    return {}; // If we don't have availabilities we don't enable anything
  }, [product.availabilities]);

  const seekStartTime = time => {
    // Validate if the start time is at xx:30 or xx:00.
    const minutes = time.minute();
    if (minutes === 0 || minutes === 30) return time;
    // If not add the remainder.
    const remainder = 30 - (time.minute() % 30);
    return moment(time).add(remainder, 'minutes');
  };

  // The useDays now depends on the daysAvailable provided from the timeslots
  const { fetchAvailabilities, bookBeautyProduct } = beautyActions(bookingEngine);
  const [selectedDay, days, actions] = useDays(9, {
    isDisabled: ({ date }) => !daysAvailable.hasOwnProperty(date.format('YYYY-MM-DD')),
    onChange: (firstDay, daysLength, daysList) => {
      if (accessToken) {
        fetchAvailabilities(
          location,
          product.sfid,
          firstDay.startOf('day').toISOString(),
          firstDay.clone().add(daysLength, 'day').startOf('day').toISOString(),
        );
      }
    },
    dependencies: [product.sfid, product.availabilities, location],
  });

  const timeslotLength = useMemo(() => product.treatment_duration || 30, [product]);

  const now = moment();
  const timeslots = useMemo(() => {
    if (selectedDay === null) return {};

    return product.availabilities.items
      .filter(
        ({ staff }) => selectedStaffMember.sfid === null || staff.sfid === selectedStaffMember.sfid,
      )
      .reduce(
        (dates, { staff, timeslots: _timeslots }) =>
          _timeslots
            .filter(({ slotDate }) => {
              const mSlotDate = moment(slotDate, 'YYYY-MM-DD');
              return mSlotDate.isSame(selectedDay.date, 'day');
            })
            .reduce(
              (timeslotDates, { slotDate, start_time: startTime, end_time: endTime, resource }) => {
                let mStart = moment(`${slotDate} ${startTime}`);
                const mEnd = moment(`${slotDate} ${endTime}`);
                if (mStart.isSame(mEnd)) {
                  mEnd.add(24, 'hour');
                }
                // Now seek the next start time, we can't do this before comparing the dates
                mStart = seekStartTime(mStart);

                const duration = moment.duration(mEnd.diff(mStart)).asMinutes();

                // We calculate the number of timeslots based on a length divisible by 30
                const roundedTimeslotLength =
                  timeslotLength % 30 > 0
                    ? timeslotLength + (30 - (timeslotLength % 30))
                    : timeslotLength;
                const nrOfTimeslots = Math.floor(duration / roundedTimeslotLength);

                return range(nrOfTimeslots).reduce((staffTimeslots, slotNr) => {
                  const slots = { ...staffTimeslots };
                  const mSlot = mStart.clone().add(slotNr * roundedTimeslotLength, 'minute');

                  if (now.isSame(selectedDay.date, 'day') && mSlot.isBefore(now)) {
                    return slots;
                  }

                  const slot = mSlot.format('HH:mm');
                  if (!slots[slot]) {
                    slots[slot] = {
                      staff: [],
                      resources: [],
                      mSlotStart: mSlot.clone(),
                    };
                  }

                  if (!slots[slot].staff.includes(staff)) {
                    slots[slot].staff.push(staff);
                  }

                  if (!slots[slot].resources.includes(resource)) {
                    slots[slot].resources.push(resource);
                  }

                  return slots;
                }, timeslotDates);
              },
              dates,
            ),
        {},
      );
  }, [product, product.availabilities.items, selectedDay, selectedStaffMember]);

  const sortedSlots = useMemo(() => Object.entries(timeslots).sort(), [timeslots]);

  const [selectedSlot, groupedTimeslots] = useGroupedTimeslots(sortedSlots, [selectedDay]);

  // Wrapper around the book event to push data to Tag Manager.
  const bookWrapper = async (selectedProduct, slot, staff, date, mStart, mEnd) => {
    const bookingResult = await bookBeautyProduct(
      product.booking_product,
      slot.resources[0].sfid,
      staff.sfid,
      concatDateTime(date, mStart).toISOString(),
      concatDateTime(date, mEnd).toISOString(),
    );
    global.TagManager.pushEvent({
      bookingSuccess: true,
      productStart: mStart.toISOString(),
      resourceId: slot.resources[0].facility.sfid,
      locationName: slot.resources[0].facility.name,
      reservationId: selectedProduct.sfid,
      reservationName: selectedProduct.name,
      type: selectedProduct.type,
    });
    return bookingResult;
  };

  const timeslotProducts = useMemo(
    () =>
      selectedSlot === null
        ? []
        : selectedSlot[1].staff.map(staff => {
            const [time, slot] = selectedSlot;
            const mEnd = slot.mSlotStart.clone().add(timeslotLength, 'minutes');

            return {
              date: selectedDay.date,
              start: slot.mSlotStart,
              end: mEnd,
              timeslotLength,
              product,
              staff,
              resource: slot.resources[0],
              book: async () =>
                bookWrapper(product, slot, staff, selectedDay.date, slot.mSlotStart, mEnd),
            };
          }),
    [selectedSlot, product],
  );

  return {
    days: { selectedDay, days, actions },
    staffMembers: selectableStaffMembers,
    timeslots: groupedTimeslots,
    timeslotProducts,
  };
};
