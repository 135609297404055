import { useEffect, useMemo, useState } from 'react';

export default (items, by, selectedByDefault = true) => {
  if (by === undefined || by === null) {
    throw new TypeError("Missing or invalid argument 'by'");
  }

  const [selected, setSelected] = useState({});
  const getId = typeof by === 'function' ? by : item => item[by];

  useEffect(() => {
    setSelected(currentlySelected => ({
      ...items.reduce(
        (out, item) => ({
          ...out,
          [getId(item)]: selectedByDefault,
        }),
        {},
      ),
      ...currentlySelected,
    }));
  }, [items, by]);

  return useMemo(
    () => [
      selected,
      items.map(item => {
        const id = getId(item);
        return {
          item,
          selected: selected[id],
          toggle: () =>
            setSelected({
              ...selected,
              [id]: !selected[id],
            }),
        };
      }),
    ],
    [selected],
  );
};
