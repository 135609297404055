import { useHealthAssessmentApi } from './useHaBookingsApi';

// TODO: Remove all actions that are not used.

export const HaActionTypes = {
  // we can rename later
  CallApi: 'CALL_API',
  AddDetails: 'ADD_DETAILS',
  GetEligibility: 'GET_ELIGIBILITY',
  AddType: 'ADD_TYPE',
  GetLocations: 'GET_LOCATIONS',
  AddLocationsWithCoords: 'ADD_LOCATIONS_WITH_COORDS',
  AddOrderedLocationsWithDistance: 'ADD_ORDERED_LOCATIONS_WITH_DISTANCE',
  AddLocation: 'ADD_LOCATION',
  GetSlots: 'GET_SLOTS',
  AddSlot: 'ADD_SLOT',
  PostAppointment: 'POST_APPOINTMENT',
  AddConfirm: 'ADD_CONFIRM',
  PutAppointment: 'PUT_APPOINTMENT',
  GetPatient: 'GET_PATIENT',
  PutPatient: 'PUT_PATIENT',
  PutPatientGenderInfo: 'PUT_PATIENT_GENDER_INFO',
  Error: 'ERROR',
  GetAppointments: 'GET_APPOINTMENTS',
  CancelAppointment: 'CANCEL_APPOINTMENT',
  AddLocationName: 'ADD_LOCATION_NAME',
  ClearSearch: 'CLEAR_SEARCH',
};
export const useHaActions = dispatch => {
  const useHaApi = useHealthAssessmentApi();

  const addDetails = detailsForm => {
    dispatch({ type: HaActionTypes.AddDetails, detailsForm });
  };

  const getEligibility = async ({ idaasId, payor }) => {
    dispatch({ type: HaActionTypes.LoadingTrue });
    try {
      const data = await useHaApi.getEligibility({ idaasId, payor });
      dispatch({ type: HaActionTypes.GetEligibility, data: data['eligibility-response'].body });
    } catch (err) {
      dispatch({ type: HaActionTypes.Error, err });
    }
  };

  const addType = haType => {
    dispatch({ type: HaActionTypes.AddType, haType });
  };

  const getLocations = async parameters => {
    dispatch({ type: HaActionTypes.LoadingTrue });
    try {
      const data = await useHaApi.getLocations(parameters);
      dispatch({
        type: HaActionTypes.GetLocations,
        data: data['locations-response'].body.locations,
      });
    } catch (err) {
      dispatch({ type: HaActionTypes.Error, err });
    }
  };

  const addLocationsWithCoords = locationsWithCoords => {
    dispatch({ type: HaActionTypes.LoadingTrue });
    dispatch({ type: HaActionTypes.AddLocationsWithCoords, locationsWithCoords });
  };

  const addOrderedLocationsWithDistance = locationsWithDistance => {
    dispatch({ type: HaActionTypes.LoadingTrue });
    dispatch({ type: HaActionTypes.AddOrderedLocationsWithDistance, locationsWithDistance });
  };

  const getAppointments = async (idaasId, appointment_filter) => {
    try {
      const data = await useHaApi.getHaBookings({ idaasId, appointment_filter });
      dispatch({ type: HaActionTypes.GetAppointments, data: data['get-appointment-result'].body });
    } catch (err) {
      dispatch({ type: HaActionTypes.Error, err });
    }
  };

  const addLocation = location => {
    dispatch({ type: HaActionTypes.AddLocation, location: location.location });
  };

  const getSlots = async parameters => {
    dispatch({ type: HaActionTypes.LoadingTrue });
    try {
      const data = await useHaApi.getSlots(parameters);
      dispatch({ type: HaActionTypes.GetSlots, data });
    } catch (err) {
      dispatch({ type: HaActionTypes.Error, err });
    }
  };

  const addSlot = slot => {
    dispatch({ type: HaActionTypes.AddSlot, slot });
  };

  const postAppointment = async body => {
    dispatch({ type: HaActionTypes.LoadingTrue });
    try {
      const data = await useHaApi.postAppointment(body);
      dispatch({ type: HaActionTypes.PostAppointment, data: data });
    } catch (err) {
      dispatch({ type: HaActionTypes.Error, err });
    }
  };

  const addConfirm = confirm => {
    dispatch({ type: HaActionTypes.AddConfirm, confirm });
  };

  const putAppointment = async (appointmentId, specialRequirements) => {
    dispatch({ type: HaActionTypes.LoadingTrue });
    try {
      const dataPending = await useHaApi.putAppointment({
        appointmentId,
        status: 'pending',
        specialRequirements
      });
      dispatch({ type: HaActionTypes.PutAppointment, data: dataPending });
      if (dataPending !== undefined) {
        const dataConfirmed = await useHaApi.putAppointment({
          appointmentId,
          status: 'booked',
          specialRequirements
        });
        dispatch({ type: HaActionTypes.PutAppointment, data: dataConfirmed });
      }
    } catch (err) {
      dispatch({ type: HaActionTypes.Error, err });
    }
  };

  const cancelAppointment = async appointmentId => {
    dispatch({ type: HaActionTypes.LoadingTrue });
    try {
      await useHaApi.putAppointment({
        appointmentId,
        status: 'cancelled',
      });
      dispatch({ type: HaActionTypes.CancelAppointment, data: true });
    } catch (err) {
      dispatch({ type: HaActionTypes.Error, err });
    }
  };

  const putPatient = async parameters => {
    dispatch({ type: HaActionTypes.LoadingTrue });
    try {
      const data = await useHaApi.putPatient(parameters);
      dispatch({ type: HaActionTypes.PutPatient, data: data });
    } catch (err) {
      dispatch({ type: HaActionTypes.Error, err });
    }
  };

  const putPatientGenderInfo = async parameters => {
    dispatch({ type: HaActionTypes.LoadingTrue });
    try {
      const data = await useHaApi.putPatientGenderInfo(parameters);
      dispatch({ type: HaActionTypes.PutPatientGenderInfo, data: data });
    } catch (err) {
      dispatch({ type: HaActionTypes.Error, err });
    }
  };

  const getPatient = async patientId => {
    dispatch({ type: HaActionTypes.LoadingTrue });
    try {
      const data = await useHaApi.getPatient(patientId);
      dispatch({ type: HaActionTypes.GetPatient, data: data });
    } catch (err) {
      dispatch({ type: HaActionTypes.Error, err });
    }
  };

  const setLocationName = name => {
    dispatch({ type: HaActionTypes.AddLocationName, data: name });
  };

  const clearSearch = () => {
    dispatch({ type: HaActionTypes.ClearSearch });
  };

  return {
    addDetails,
    getEligibility,
    addType,
    getLocations,
    addLocationsWithCoords,
    addOrderedLocationsWithDistance,
    addLocation,
    getSlots,
    addSlot,
    postAppointment,
    addConfirm,
    putAppointment,
    getPatient,
    putPatient,
    putPatientGenderInfo,
    getAppointments,
    cancelAppointment,
    setLocationName,
    clearSearch,
  };
};
