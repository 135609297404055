import React from 'react';

import './GoogleAutocompleteSuggestion.sass';

export default ({ suggestion, onClick }) => {
  const {
    place_id,
    structured_formatting: { main_text, secondary_text },
  } = suggestion;

  return (
    <li key={place_id} onClick={onClick} onKeyPress={onClick} className="place-item" tabIndex={0}>
      <strong>{main_text},</strong> <small>{secondary_text}</small>
    </li>
  );
};
