import useActions from '../hooks/useActions';
import consultants from '../reducers/consultants';

export default (consultantBookings, bookingEngine) =>
  useActions({
    fetchConsultantClinicDays:
      ({ gmcCode, span, fromDate }) =>
      async dispatch => {
        const [{ results, consultantName }, { price }] = await Promise.all([
          await consultantBookings.getConsultantClinicDays({ gmcCode, span, fromDate }),
          await bookingEngine.getConsultantPricing({ gmcCode }),
        ]);

        dispatch({
          type: consultants.types.setHospitals,
          payload: results.reduce(
            (accumulator, { locations }) =>
              locations.reduce(
                (accumulator2, { hospitalId, ...hospital }) => ({
                  ...accumulator2,
                  [hospitalId]: hospital,
                }),
                accumulator,
              ),
            {},
          ),
        });

        dispatch({
          type: consultants.types.setConsultants,
          payload: {
            [gmcCode]: {
              name: consultantName,
              price,
            },
          },
        });

        dispatch({
          type: consultants.types.setClinicDays,
          payload: results.map(({ locations, ...result }) => ({
            ...result,
            locations: locations.map(({ hospitalId }) => hospitalId),
          })),
        });
      },

    clearConsultantSlots: () => async dispatch => {
      dispatch({
        type: consultants.types.setSlots,
        payload: [],
      });
    },

    fetchConsultantSlots:
      ({ gmcCode, fromDate, locations }) =>
      async dispatch => {
        const responses = await Promise.all(
          locations.map(hospitalId =>
            consultantBookings.getSlotsAvailability({
              fromDate,
              gmcCode,
              hospitalId,
            }),
          ),
        );

        dispatch({
          type: consultants.types.setSlots,
          payload: responses.reduce(
            (slots, { response: { responseData } }, index) => [
              ...slots,
              ...responseData.bookingDetails.map(slot => ({
                ...slot,
                hospitalId: locations[index],
                specialty: responseData.specialty,
              })),
            ],
            [],
          ),
        });
      },

    bookConsultantSlot:
      ({ gmcCode, ...params }) =>
      async dispatch =>
        consultantBookings.bookSlot({
          gmcCode,
          ...params,
        }),
  });
