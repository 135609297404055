import React, { useMemo } from 'react';
import FlatList from './FlatList';
import './DaysToggle.sass';
import { ReactComponent as ArrowRight } from '../static/img/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../static/img/arrow-left.svg';

export default ({ selectedDay, days, actions, showMonth }) => {
  const month = useMemo(() => {
    const { date } = selectedDay || days[0].item;
    return date.format('MMMM');
  }, [selectedDay, days]);

  return (
    <>
      {showMonth && (
        <div className="month-navigation__wrapper">
          {actions && (
            <button className="prev-month__btn" onClick={actions.prevMonth}>
              <ArrowLeft />
            </button>
          )}
          <div className="month-name">{month}</div>
          {actions && (
            <button className="next-month__btn" onClick={actions.nextMonth}>
              <ArrowRight />
            </button>
          )}
        </div>
      )}
      <div className="days-toggle__outer-wrapper">
        {actions && (
          <button className="prev-period__btn" onClick={actions.prev}>
            <ArrowLeft />
          </button>
        )}
        <div className="days-toggle__scroll">
          <div className="days-toggle__wrapper">
            <FlatList
              data={days}
              renderItem={({ item: { date }, disabled, selected, select }, index) => (
                <button
                  type="button"
                  className={`day-toggle__button ${selected && 'm--active'} ${disabled && 'm--disabled'}`}
                  key={index}
                  onClick={() => {
                    if (!disabled) select();
                  }}
                >
                  {date.format('ddd')}
                  <br />
                  {date.format('D MMM')}
                </button>
              )}
            />
          </div>
        </div>
        {actions && (
          <button className="next-period__btn" onClick={actions.next}>
            <ArrowRight />
          </button>
        )}
      </div>
    </>
  );
};
