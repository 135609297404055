export default (product, location, { closeAvailabilities, openAvailabilities }) => {
  let type;
  let name;
  let call;

  const openAvailabilitiesWrapper = async () => {
    global.TagManager.pushEvent({
      bookingButtonClicked: true,
      resourceId: location,
      reservationName: product.name,
      type: product.treatment_type,
    });
    return openAvailabilities(location, product.sfid);
  };

  if (product.availabilities.visible) {
    type = 'CLOSE_AVAILABILITIES';
    name = 'Close';
    call = () => closeAvailabilities(location, product.sfid);
  } else {
    type = 'OPEN_AVAILABILITIES';
    name = 'Select';
    call = openAvailabilitiesWrapper;
  }

  return { name, type, call };
};
