import { useMemo, useEffect } from 'react';
import moment from 'moment-timezone';

import useDays from '../useDays';
import useGroupedTimeslots from '../useGroupedTimeslots';

export const useHaBookings = slots => {
  const slotsDaysAvailable = useMemo(
    () =>
      slots?.entry.map(({ resource: { start } }) => moment(new Date(start)).format('YYYY-MM-DD')) ??
      [],
    [slots],
  );
  const tomorrow = useMemo(() => moment().startOf('day').add(1, 'day'), []);

  const [selectedDay, days, actions, setFirstDay] = useDays(9, {
    startDate: tomorrow,
    endDate: moment().startOf(tomorrow).add(90, 'days'),
    isDisabled: ({ date }) => !slotsDaysAvailable.includes(date.format('YYYY-MM-DD')),
    dependencies: [slots],
  });

  useEffect(() => {
    const sort = [...slotsDaysAvailable]?.sort((a, b) => moment(a).diff(moment(b)));
    const day = moment(
      sort.find(slotDate => {
        const slotD = moment(slotDate);
        return slotD.isSameOrAfter(tomorrow);
      }),
    );
    setFirstDay(day);
  }, [slotsDaysAvailable]);

  const timeslots = useMemo(
    () =>
      selectedDay && slots
        ? slots?.entry
            .filter(({ resource: { start } }) =>
              moment(new Date(start)).isSame(selectedDay?.date, 'day'),
            )
            .reduce((prev, { resource }) => {
              const newTimeslots = { ...prev };
              const mSlotTime = moment(new Date(resource.start), 'HH.mm');
              const slot = mSlotTime.format('HH:mm');

              if (!newTimeslots.hasOwnProperty(slot)) {
                newTimeslots[slot] = [];
              }

              newTimeslots[slot].push({
                ...resource,
              });

              return newTimeslots;
            }, {})
        : {},
    [slots, selectedDay],
  );

  const sortedTimeslots = useMemo(() => Object.entries(timeslots).sort(), [timeslots]);
  const [selectedSlot, groupedTimeslots] = useGroupedTimeslots(sortedTimeslots, [selectedDay]);
  return {
    days: { selectedDay, days, actions },
    timeslots: groupedTimeslots,
    selectedSlot: selectedSlot ? selectedSlot[1] : [],
  };
};
