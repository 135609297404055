export default value => {
  //the regular expression will accept all alphabet thats a-z small and capital letters
  //as well as any numbers and a few special characters such as & . and , but anything
  //else will not be accepted for example Seán will not be accepted as the á is a special
  //character that is not accepted by integrations hub
  const validateName = new RegExp("^[a-zA-Z0-9'&,\\.-]*$");
  if (!validateName.test(value)) {
    return false;
  } else {
    return true;
  }
};
