import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import useMultiSelect from '../useMultiSelect';
import useBookingEngine from '../useBookingEngine';
import gymClassActions from '../../actions/gymClassActions';

export default () => {
  const bookingEngine = useBookingEngine();
  const { fetchCategories } = gymClassActions(bookingEngine);

  const categories = useSelector(({ gymClassCategories }) => gymClassCategories);
  const [lastFetched, setLastFetched] = useState(0);
  const [fetching, setFetching] = useState();

  const _fetchCategories = useCallback(async () => {
    if (!fetching) {
      setFetching(true);
      await fetchCategories();
      setLastFetched(moment().unix());
      setFetching(false);
    }
  }, [fetching]);

  const now = moment();
  const mLastFetched = moment.unix(lastFetched);
  const secondsSinceLastFetched = moment.duration(now.diff(mLastFetched)).asSeconds();

  if (categories === null || secondsSinceLastFetched >= 300) {
    _fetchCategories();
  }

  const _categories = useMemo(() => (Array.isArray(categories) ? categories : []), [categories]);

  return useMultiSelect(_categories, 'value', false);
};
