import React from 'react';
import moment from 'moment-timezone';

export default ({ item }) => (
  <button
    onClick={() => {
      item.select();
      // item.action.call();
    }}
    className={`timeslot__btn ${item.selected ? 'm--active' : ''}`}
  >
    {moment(item.item.from_date).format('HH:mm')}
  </button>
);
