import { UserManager } from 'oidc-client';
import BaseB2CAuthentication from './BaseB2CAuthentication';
import getQueryParameters from '../utils/getQueryParameters';

const DEFAULTS = {
  instance: process.env.REACT_APP_B2C_INSTANCE,
  tenant: process.env.REACT_APP_B2C_TENANT,
  applicationId: process.env.REACT_APP_B2C_APPLICATION_ID,
  applicationScope: process.env.REACT_APP_B2C_APPLICATION_SCOPE,
  signInPolicy: process.env.REACT_APP_B2C_SIGNIN_POLICY,
  redirectUrl: process.env.REACT_APP_B2C_REDIRECT_URL,
};

export default class OIDCB2CAuthentication extends BaseB2CAuthentication {
  constructor({
    instance,
    tenant,
    applicationId,
    applicationScope,
    signInPolicy,
    redirectUrl,
  } = DEFAULTS) {
    const b2c = new UserManager({
      metadataUrl: `${instance}/${tenant}/v2.0/.well-known/openid-configuration?p=${signInPolicy}`,
      authority: `${instance}/${tenant}/v2.0/`,
      client_id: applicationId,
      redirect_uri: redirectUrl,
      post_logout_redirect_uri: `${redirectUrl}?logout=true`,
      automaticSilentRenew: true,
      loadUserInfo: false,
      response_type: 'token id_token',
      scope: `openid ${applicationScope}`,
    });
    super(b2c);
  }

  async login() {
    sessionStorage.setItem('returnUrl', window.location.pathname);
    sessionStorage.setItem('returnType', 'login');

    const queryParams = getQueryParameters();
    if (queryParams.iframe === 'true') {
      window.top.location.href = queryParams.loginUrl;
    } else {
      await this.b2c.signinRedirect();
    }
  }

  async logout() {
    sessionStorage.setItem('returnUrl', window.location.pathname);
    sessionStorage.setItem('returnType', 'logout');
    await this.b2c.signoutRedirect();
  }

  async silentLogin() {
    sessionStorage.setItem('returnUrl', window.location.pathname);
    sessionStorage.setItem('returnType', 'silent');
    try {
      const user = await this.b2c.signinSilent();
      return user.expired ? null : user;
    } catch (e) {
      return null;
    }
  }

  async refresh() {
    return this.login();
  }

  async callback() {
    const returnUrl = sessionStorage.getItem('returnUrl');
    const returnType = sessionStorage.getItem('returnType');

    switch (returnType) {
      case 'login':
        return {
          user: await this.b2c.signinRedirectCallback(),
          returnUrl,
        };
      case 'logout':
        await this.b2c.signoutRedirectCallback();
        return { user: null, returnUrl };
      case 'silent':
        await this.b2c.signinSilentCallback();
        return null;
      default:
        return null;
    }
  }
}
