import { useMemo } from 'react';
import HealthAssessmentBookings from '../../services/HealthAssessmentBookings';
import errorActions from '../../actions/errorActions';
import useAuthentication from '../useAuthentication';

export const useHealthAssessmentApi = () => {
  const { refresh, access_token: accessToken } = useAuthentication();
  const { log: logError } = errorActions();
  return useMemo(
    () => HealthAssessmentBookings.getInstance(accessToken, refresh, logError),
    [accessToken],
  );
};
