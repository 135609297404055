const distanceBetween = (point1, point2) => {
  // Haversine algorithm
  const EARTH_RADIUS_IN_MILES = 3958.8;

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  // Convert latitude and longitude from degrees to radians
  const valuesArray1 = Object.values(point1);
  const valuesArray2 = Object.values(point2);
  const lat1 = deg2rad(valuesArray1[0]);
  const lon1 = deg2rad(valuesArray1[1]);
  const lat2 = deg2rad(valuesArray2[0]);
  const lon2 = deg2rad(valuesArray2[1]);

  // Haversine formula
  const dLat = lat2 - lat1;
  const dLon = lon2 - lon1;
  const a = Math.sin(dLat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = EARTH_RADIUS_IN_MILES * c;

  return distance; // Distance in miles
};

const geoLocationAPI = window.navigator.geolocation;
const getPosition = (successCb, errorCb, options) => {
  if (geoLocationAPI) {
    return geoLocationAPI.getCurrentPosition(successCb, errorCb, options);
  }
  return null;
};

const getLatLng = async address => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GMAPS_KEY}`,
    );
    const data = await response.json();
    if (data.status === 'OK') {
      const location = data.results[0].geometry.location;
      return location;
    } else {
      console.error('Geocode was not successful');
    }
  } catch (error) {
    console.error('Error fetching geocode');
  }
};

export { getPosition, distanceBetween, getLatLng };
