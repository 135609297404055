import React from 'react';
import { ReactComponent as Checkmark } from '../static/img/checkmark.svg';
import ordinalNumber from '../utils/ordinalNumber';

export default ({ item, mobile = false }) =>
  item.action !== null && (
    <div className={`class-status bold ${mobile ? 'mobile-only' : 'desktop-only'}`}>
      {(() => {
        switch (item.action.type) {
          case 'CANCEL_BOOKING':
            return (
              <span className="product-booked">
                <span className="booked-icon">
                  <Checkmark />
                </span>
                Booked
              </span>
            );
          case 'LEAVE_WAITLIST':
            return (
              <span className="class-status-waiting">
                {`${ordinalNumber(item.my_booking.waitlist_position)} on waitlist`}
              </span>
            );
          case 'BOOK':
            return (
              <span className="class-status-regular">
                {`${item.attendance_capacity - item.attendees} spaces`}
              </span>
            );
          case 'JOIN_WAITLIST':
            return (
              <span className="class-status-waiting">
                {`${item.members_on_waiting_list} waiting`}
              </span>
            );
          case 'IS_FULL':
            return <span className="class-status-full">Unavailable</span>;
          case 'TO_EARLY':
            return <span className="class-status-unavailable">Available at 7am</span>;
          default:
            return null;
        }
      })()}
    </div>
  );
