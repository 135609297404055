import createReducer from '../utils/createReducer';

const initialState = {};

export default createReducer('modals', initialState, {
  showModal: (state, { type, data }) => ({
    ...state,
    [type]: data,
  }),
  hideModal: (state, { type }) => ({
    ...state,
    [type]: null,
  }),
});
