import moment from 'moment-timezone';
import React, { useRef } from 'react';
import { ReactComponent as Location } from '../static/img/location.svg';
import { ReactComponent as PersonPlus } from '../static/img/person-plus.svg';
import CTAButton from './CTAButton';
import { ReactComponent as Arrow } from '../static/img/arrow-alt.svg';
import { BOOKING_STATES } from '../enums/booking';

import './EventProduct.sass';

export default ({ item, toggleActions = false }) => {
  const ctaWrapperRefs = useRef([]);
  ctaWrapperRefs.current = [];

  const addToRefs = el => {
    if (el && !ctaWrapperRefs.current.includes(el)) {
      ctaWrapperRefs.current.push(el);
    }
  };

  const toggleCtaOverlay = sfid => {
    const currentRef = ctaWrapperRefs.current.find(el => el.dataset.sfid === sfid);
    currentRef.classList.toggle('m--cta-wrapper-open');
  };

  const {
    sfid,
    title,
    consultant,
    room,
    from_date: fromDate,
    cancellation_time: cancellationTime,
    leave_wait_list_time: leaveWaitListTime,
    has_waitlist: hasWaitList,
    to_date: toDate,
    my_booking: myBooking,
    alternative_venue: venue,
  } = item;

  const registerCTA = clickedItem => {
    global.TagManager.pushEvent({
      bookingButtonClicked: true,
      productStart: clickedItem.from_date,
      resourceId: clickedItem.room.facility.sfid,
      locationName: clickedItem.room.facility.name,
      reservationId: clickedItem.sfid,
      reservationName: clickedItem.product.title,
      type: clickedItem.product.event_type,
    });
  };

  return (
    <div
      className={`class__wrapper m--${item.type.toLowerCase()} ${myBooking ? `m--${myBooking.status.replace(/\s+/g, '-')}` : ''}`}
      key={sfid}
    >
      <div className="class-content__wrapper">
        <div className="class-time__wrapper">
          <div className="class__detail bold">{`${moment(fromDate).format('dddd Do MMM YYYY')}`}</div>
          <div className="class-time bold">{`${moment(fromDate).format('HH:mm')} - ${moment(toDate).format('HH:mm')}`}</div>
        </div>
        <div className="class-details__wrapper">
          <div className="class-title bold">{title}</div>
          <div className="class__detail">
            <Location />
            {venue ? venue.alternative_venue_name : `${room.name} at ${room.facility.name}`}
          </div>
          <div className="class__detail">
            <PersonPlus />
            {consultant}
          </div>
        </div>
        <div className="cta__wrapper" data-sfid={sfid} ref={el => addToRefs(el)}>
          <div
            className={`cta-background 
            ${item.action !== null && item.action.type !== null ? `m--${item.action.type.toLowerCase()}` : ''}
            ${item.has_waitlist && 'm--has-waitlist'}
            `}
          >
            {toggleActions ? (
              <CTAButton
                action={toggleActions}
                onError={e => {
                  alert(JSON.stringify(e));
                  console.trace(e);
                }}
              />
            ) : (
              <CTAButton
                action={item.action}
                onAction={() => registerCTA(item)}
                onError={e => {
                  alert(JSON.stringify(e));
                  console.trace(e);
                }}
              />
            )}
          </div>
        </div>
        <button
          className="toggle-class-overlay__button mobile-only"
          onClick={() => toggleCtaOverlay(sfid)}
        >
          <Arrow />
        </button>
      </div>
    </div>
  );
};
