import moment from 'moment-timezone';
import concatDateTime from '../../../utils/concatDateTime';

export default (selectedDay, selectedTime) => {
  const fromDateTime = concatDateTime(selectedDay.date, moment(selectedTime.from, 'HH:mm'));
  const toDateTime = concatDateTime(selectedDay.date, moment(selectedTime.to, 'HH:mm'));

  return ({ from_date: fromDate }) =>
    moment(fromDate).isBetween(fromDateTime, toDateTime, null, '[)');
};
