import React from 'react';
import { useLocation } from 'react-router';
import AddToCalendar from 'react-add-to-calendar';
import './BeautyConfirm.sass';
import moment from 'moment-timezone';
import { ReactComponent as Checkmark } from '../static/img/checkmark.svg';
import { ReactComponent as ArrowRight } from '../static/img/arrow-right.svg';

export default () => {
  const { state } = useLocation();
  const { date, start, end, product, staff, resource } = state;

  const mDate = moment(date);
  const mStart = moment(start);
  const mEnd = moment(end);

  return (
    <div className="page m--beautyconfirm">
      <div className="pattern" />
      <div className="header__wrapper">
        <div className="header">
          <div className="h1">Book beauty treatment</div>
        </div>
      </div>
      <div className="booked">
        <span className="icon__wrapper">
          <Checkmark />
        </span>{' '}
        Booked
      </div>
      <span className="divider" />
      <div className="h1 mt-30">{product.name}</div>
      <div className="details__wrapper">
        <table className="details">
          <tr>
            <td>Time</td>
            <td>
              {`${mStart.format('HH:mm')} - ${mEnd.format('HH:mm')} ${mDate.format('dddd D MMMM')}`}
            </td>
          </tr>
          <tr>
            <td>Therapist</td>
            <td>{staff.name}</td>
          </tr>
          <tr>
            <td>Location</td>
            <td>{`${resource.name} at ${resource.facility.name}`}</td>
          </tr>
          <tr>
            <td>Total to pay in club</td>
            <td>{`£ ${product.price}`}</td>
          </tr>
        </table>
        <div>
          <div className="add-to-calendar-link__wrapper">
            <AddToCalendar
              event={{
                title: `${product.name}`,
                location: `${resource.name} at ${resource.facility.name}`,
                description: '',
                startTime: mStart.format(),
                endTime: mEnd.format(),
              }}
            />
            <span className="icon__wrapper">
              <ArrowRight />
            </span>
          </div>
        </div>
        <div>
          <div className="h2">About your booking</div>
          <div className="wysiwyg">
            {product.prerequisite && (
              <div
                dangerouslySetInnerHTML={{
                  __html: `Dont forget ${product.prerequisite}`,
                }}
              />
            )}
          </div>
        </div>
      </div>
      <span className="divider" />
    </div>
  );
};
