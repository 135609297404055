import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as Location } from '../static/img/location.svg';
import { ReactComponent as PersonPlus } from '../static/img/person-plus.svg';
import Modal from '../components/Modal';
import HABookingStatus from '../components/HABookingStatus';
import { useHealthAssessmentCtx } from '../contexts';
import './HealthAssessmentBookings.sass';
import { useLocation } from 'react-router-dom';
import ordinalNumber from '../utils/ordinalNumber';

export default () => {
  // Get IDaaS ID from query params
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idaasId = searchParams.get('idaas-id');

  const {
    haCtxSelectors: { appointments, appointmentCancelled },
    state,
    haCtxActions,
  } = useHealthAssessmentCtx();

  const [selectedTab, setSelectedTab] = useState('upcoming');

  const appointmentData = appointments();

  useEffect(() => {
    const getAppointments = async () => await haCtxActions.getAppointments(idaasId, 'HA');
    getAppointments();
  }, []);

  const haDetailsRef = useRef();
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const handleViewDetails = (event, appointmentData) => {
    event.preventDefault();
    setSelectedAppointment(appointmentData);
    haDetailsRef.current.show();
  };

  const haCancelRef = useRef();

  const handleCancelAppointment = (event, appointmentData) => {
    event.preventDefault();
    setSelectedAppointment(appointmentData);
    haCancelRef.current.show();
  };

  const cancelAppointment = async appointmentSectionId =>
    await haCtxActions.cancelAppointment(appointmentSectionId);
  const handleCloseAfterCancel = () => {
    haCancelRef.current.hide();
    window.location.reload();
  };

  const filteredAppointments = appointmentData?.filter(appointment => {
    const startDate = new Date(appointment['appointment-start-datetime']);
    const status = appointment['appointment-status'];
    const now = new Date();

    // Show past and cancelled bookings under past tab
    if (selectedTab === 'upcoming') {
      return startDate > now && status !== 'cancelled';
    } else {
      return startDate <= now || status === 'cancelled';
    }
  });

  let appointmentWithinThreeWorkingDays = appointmentData?.some(obj => {
    const startDate = new Date(obj['appointment-start-datetime']);
    const status = obj['appointment-status'];
    const now = new Date();
    
    let daysToLookForward = 3;
    let dayCount = 0;
    let futureDate = new Date(now);
  
    while (dayCount < daysToLookForward) {
      futureDate.setDate(futureDate.getDate() + 1);
      const dayOfWeek = futureDate.getDay();

      // ignore saturday/sunday in days to check
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        dayCount++;
      }
    }
    
    return startDate > now && startDate < futureDate && status !== 'cancelled';
  });

  return (
    <>
      <div className="ha-bookings">
        <div className={`page ${'m--past-bookings'}`}>
          <div className="tabs__wrapper">
            <button
              className={`tab__item ${selectedTab === 'past' ? 'm--active' : ''}`}
              onClick={() => setSelectedTab('past')}
            >
              Past
            </button>
            <button
              className={`tab__item ${selectedTab === 'upcoming' ? 'm--active' : ''}`}
              onClick={() => setSelectedTab('upcoming')}
            >
              Upcoming
            </button>
          </div>

          {(filteredAppointments == null ||
            (filteredAppointments.length === 0 && selectedTab === 'upcoming')) && (
            <div>
              <div className="no-items-found">No upcoming health assessments</div>
              <span className="divider" />
            </div>
          )}

          {(filteredAppointments == null ||
            (filteredAppointments.length === 0 && selectedTab === 'past')) && (
            <div>
              <div className="no-items-found">No items found</div>
              <span className="divider" />
            </div>
          )}

          {filteredAppointments?.map(
            (
              {
                ['appointment-id']: appointmentId,
                ['appointment-section-id']: appointmentSectionId,
                ['appointment-type-name']: appointmentTypeName,
                ['appointment-start-datetime']: appointmentStart,
                ['appointment-end-datetime']: appointmentEnd,
                ['appointment-locations']: appointmentLocations,
                ['appointment-practitioners']: appointmentPractitioners,
                ['appointment-status']: appointmentStatus,
              },
              index,
            ) => {
              const startDate = new Date(appointmentStart);
              const endDate = new Date(appointmentEnd);
              const dayOfWeek = startDate.toLocaleString('en-GB', { weekday: 'long' });
              const month = startDate.toLocaleString('en-GB', { month: 'long' });
              const dayOfMonth = startDate.getDate();
              const year = startDate.getFullYear();
              const formattedDate = `${dayOfWeek} ${ordinalNumber(dayOfMonth)} ${month} ${year}`;
              const durationInMilliseconds = endDate - startDate;
              const durationInHours = durationInMilliseconds / (1000 * 60 * 60);
              const startTime = startDate.toTimeString().slice(0, 5);
              const endTime = endDate.toTimeString().slice(0, 5);
              const locations = appointmentLocations;
              const practitioners = appointmentPractitioners;
              const sectionId = appointmentSectionId;

              return (
                <div key={appointmentId}>
                  <div className="class-content__wrapper">
                    <div className="class-time__wrapper">
                      <div className="class-time bold">
                        {startTime} - {endTime}
                      </div>
                      <div className="class-date">{formattedDate}</div>
                    </div>
                    <div className="class-details__wrapper">
                      <div className="ha-tile-header">
                        <h2 className="h2 bold">{appointmentTypeName}</h2>
                        <HABookingStatus status={appointmentStatus} />
                      </div>
                      <div className="class__detail">
                        <Location />
                        <span>{locations[0]['location-display-name']}</span>
                      </div>
                      <div>
                        {practitioners.map((practitioner, index) => (
                          <div className="detail" key={practitioner['practitioner-id']}>
                            <span className="location-icon__wrapper">
                              {index === 0 && <PersonPlus />}
                            </span>
                            <span className="location-name">
                              {practitioner['practitioner-name']}
                            </span>
                          </div>
                        ))}
                      </div>
                      <br></br>
                      <a
                        className="link view-details"
                        href="#"
                        onClick={event =>
                          handleViewDetails(event, {
                            appointmentTypeName,
                            formattedDate,
                            startTime,
                            endTime,
                            durationInHours,
                            locations,
                            practitioners,
                          })
                        }
                      >
                        View details
                      </a>
                    </div>
                  </div>

                  {selectedTab === 'upcoming' && (
                    <div className="cancellation-container">
                      <div className="cancellation-actions">
                        {!appointmentWithinThreeWorkingDays && (
                          <button
                            className="primary-btn m--red"
                            href="#"
                            onClick={event =>
                              handleCancelAppointment(event, {
                                sectionId,
                                formattedDate,
                                startTime,
                                appointmentTypeName,
                              })
                            }
                          >
                            Cancel appointment
                          </button>
                        )}
                        <p>
                          If you would like to re-arrange your appointment please contact us as soon
                          as you can on <a href="tel:03001311550">0300 131 1550</a>
                        </p>
                      </div>
                      <div className="cancellation-message">
                        <p className="bold">
                          A 50% charge applies for cancellations or changes made with less than
                          three full working days’ notice. You’ll be charged the full fee if you
                          fail to attend or arrive over 15 minutes late.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              );
            },
          )}
          <br></br>

          {selectedTab === 'upcoming' && (
            <div>
              <h2 className="h2">Health assessment information</h2>
              <p>
                If you have a Health Assessment coming up, there’s a few things you’ll need to do
                beforehand.{' '}
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  className="link"
                  href="https://www.nuffieldhealth.com/health-assessments-hub/preparing-for-your-health-assessment-appointment"
                >
                  Read our guide
                </a>
              </p>
              <p>
                Need help with your Health Assessment appointment?{' '}
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  className="link"
                  href="https://www.nuffieldhealth.com/health-assessments-hub/our-health-assessments-faqs"
                >
                  Read our FAQs
                </a>
              </p>
            </div>
          )}

          <Modal type="haCancel" ref={haCancelRef}>
            {() =>
              !appointmentCancelled() && selectedAppointment ? (
                <div>
                  <h2 className="h2">Cancel your appointment?</h2>
                  <p>
                    Are you sure you want to cancel your {selectedAppointment.appointmentTypeName}{' '}
                    health assessment on {selectedAppointment.formattedDate}{' '}
                    {selectedAppointment.startTime}
                  </p>
                  <div className="cancellation-message">
                    <p>
                      A 50% charge applies for cancellations or changes made with less than three
                      full working days’ notice. You’ll be charged the full fee if you fail to
                      attend or arrive over 15 minutes late.
                    </p>
                  </div>
                  <div className="btn-container">
                    <button className="secondary-btn" onClick={() => haCancelRef.current.hide()}>
                      No, go back
                    </button>
                    <button
                      className="primary-btn"
                      onClick={() => {
                        cancelAppointment(selectedAppointment.sectionId);
                      }}
                    >
                      Cancel and continue
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <h2 className="h2">Your appointment has been cancelled.</h2>
                  <div className="btn-container">
                    <button className="primary-btn" onClick={() => handleCloseAfterCancel()}>
                      Close
                    </button>
                  </div>
                </div>
              )
            }
          </Modal>

          <Modal type="haDetails" ref={haDetailsRef}>
            {() =>
              selectedAppointment && (
                <div>
                  <div className="class-title bold">{selectedAppointment.appointmentTypeName}</div>
                  <br></br>
                  <table className="modal-details__table">
                    <tbody>
                      <tr>
                        <td>Date:</td>
                        <td>{selectedAppointment.formattedDate.toString()}</td>
                      </tr>
                      <tr>
                        <td>Time:</td>
                        <td>
                          <div>
                            {selectedAppointment.startTime.toString()} -{' '}
                            {selectedAppointment.endTime.toString()}
                          </div>
                          <p>Please aim to arrive 15 minutes before your appointment.</p>
                        </td>
                      </tr>
                      <tr>
                        <td>Duration:</td>
                        <td>{selectedAppointment.durationInHours} hours</td>
                      </tr>
                      <tr>
                        <td>Location:</td>
                        <td>
                          <div className="bold">
                            {selectedAppointment.locations[0]['location-display-name']}
                          </div>
                          <div>{selectedAppointment.locations[0]['location-name']}</div>
                          <div>{selectedAppointment.locations[0]['location-address-line-1']}</div>
                          <div>{selectedAppointment.locations[0]['location-address-line-2']}</div>
                          <div>{selectedAppointment.locations[0]['location-city']}</div>
                          <div>{selectedAppointment.locations[0]['location-postcode']}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>Practitioner(s):</td>
                        <td>
                          <p>
                            For your assessment you may be viewed by multiple practitioners on the
                            day.
                          </p>
                          {selectedAppointment.practitioners.map((practitioner, index) => (
                            <div key={index}>{practitioner['practitioner-name']}</div>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            }
          </Modal>
        </div>
      </div>
    </>
  );
};
