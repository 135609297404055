export default (item, { selectEvent }) => {
  const { is_full: isFull } = item;

  let call = () => selectEvent(item.sfid);
  let name = 'Book';
  let type = 'BOOK';

  if (isFull) {
    name = 'Event Full';
    type = 'IS_FULL';
    call = null;
  }

  return {
    name,
    type,
    call,
  };
};
