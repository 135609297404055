import React from 'react';
import PropTypes from 'prop-types';
import './HABookingStatus.sass';

const HABookingStatus = ({ status }) => {
  const vitaApiAppointmentStatusToCleanStatus = {
    proposed: { cleanStatus: 'Pending', cssModifier: 'green' },
    pending: { cleanStatus: 'Pending', cssModifier: 'green' },
    booked: { cleanStatus: 'Confirmed', cssModifier: 'green' },
    fulfilled: { cleanStatus: 'Attended', cssModifier: 'light-green' },
    arrived: { cleanStatus: 'In progress', cssModifier: 'green' },
    cancelled: { cleanStatus: 'Cancelled', cssModifier: 'red' },
    noshow: { cleanStatus: 'No show', cssModifier: 'red' },
  };

  const { cleanStatus, cssModifier } = vitaApiAppointmentStatusToCleanStatus[status] || {
    cleanStatus: status,
    cssModifier: 'green',
  };
  const className = `ha-appointment-status ha-appointment-status__${cssModifier} bold`;
  return (
    <div className={className}>
      <span>{cleanStatus}</span>
    </div>
  );
};

HABookingStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default HABookingStatus;
