import React, { useCallback, useRef, useState } from 'react';
import OutsideClickHandler from './OutsideClickHandler';
import { ReactComponent as Arrow } from '../static/img/arrow-alt.svg';

import './SelectBox.sass';

export default ({ children, title, type, data }) => {
  const ref = useRef(null);
  const selectedItems = data.filter(item => item.selected);
  const [open, setOpen] = useState(false);

  const toggleSelect = useCallback(() => {
    if (data.length > 0) {
      setOpen(!open);
    }
  }, [data, open, setOpen]);

  return (
    <>
      <div
        className={`custom-select__wrapper ${data.length === 0 ? 'm--disabled' : ''} ${open ? 'm--custom-select-open' : ''}`}
        ref={ref}
      >
        <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
          <button className="custom-select__name" type="button" onClick={toggleSelect}>
            {type === 'checkbox' &&
              (selectedItems.length > 0 ? `${title} (${selectedItems.length})` : title)}
            {type === 'radio' && (selectedItems.length > 0 ? selectedItems[0].item.name : title)}
            <Arrow />
          </button>
          {type === 'checkbox' && children}
        </OutsideClickHandler>
        {type === 'radio' && children}
      </div>
    </>
  );
};
