import moment from 'moment-timezone';
import React, { useMemo, useRef } from 'react';
import AddToCalendar from 'react-add-to-calendar';
import FlatList from './FlatList';
import CTAButton from './CTAButton';
import { ReactComponent as Location } from '../static/img/location.svg';
import { ReactComponent as PersonPlus } from '../static/img/person-plus.svg';
import { ReactComponent as Checkmark } from '../static/img/checkmark.svg';
import { ReactComponent as ArrowRight } from '../static/img/arrow-right.svg';
import { ReactComponent as Arrow } from '../static/img/arrow-alt.svg';

import './GymClassList.sass';
import { BOOKING_STATES } from '../enums/booking';
import ClassStatus from './ClassStatus';
import Modal from './Modal';

export default ({
  gymClasses,
  showDate = false,
  hideOverlay = false,
  renderDetails = () => null,
  reservationDetails = false,
}) => {
  const detailsModalRef = useRef();
  const descriptionModalRef = useRef();
  const confirmModalRef = useRef();

  const modalTypes = {
    details: detailsModalRef,
    description: descriptionModalRef,
    confirm: confirmModalRef,
  };

  const ctaWrapperRefs = useRef([]);
  ctaWrapperRefs.current = [];

  const toggleCtaOverlay = sfid => {
    const currentRef = ctaWrapperRefs.current.find(el => el.dataset.sfid === sfid);
    currentRef.classList.toggle('m--cta-wrapper-open');
  };

  const addToRefs = el => {
    if (el && !ctaWrapperRefs.current.includes(el)) {
      ctaWrapperRefs.current.push(el);
    }
  };

  const detailOrDescriptionModalRef = useMemo(
    () => (reservationDetails ? detailsModalRef : descriptionModalRef),
    [reservationDetails],
  );

  return (
    <div className="m--gymclass-list">
      <Modal type="gymclass_confirm" ref={confirmModalRef}>
        {({ title, header, confirm, deny }, hide) => (
          <>
            <h3 className="modal-subtitle">{header}</h3>
            <p className="modal-text">{title}</p>
            <div className="modal-buttons__wrapper">
              {deny ? (
                <CTAButton
                  action={deny}
                  onAction={() => hide()}
                  className="modal-button modal-outline__button"
                />
              ) : null}
              {confirm && (
                <CTAButton
                  useLoading
                  action={confirm}
                  onAction={() => hide()}
                  onError={() => hide()}
                  className="modal-button modal-cta__button"
                />
              )}
            </div>
          </>
        )}
      </Modal>

      <Modal type="gymclass_description" ref={descriptionModalRef}>
        {({ reservation: { title, room, product } }) => (
          <>
            {title && (
              <h3 className="modal-title">
                <span className="modal-icon__wrapper">
                  <Checkmark />
                </span>
                {title}
              </h3>
            )}
            <h3 className="modal-subtitle">{`${product.name} at ${room.facility.name}`}</h3>
            <div>{product.description}</div>
            {product.what_to_bring && (
              <div className="class-what-to-bring">
                <strong>What should I bring?</strong>
                <p>{product.what_to_bring}</p>
              </div>
            )}
          </>
        )}
      </Modal>

      <Modal type="gymclass_details" ref={detailsModalRef}>
        {(
          {
            title,
            cancelTime,
            reservation: {
              from_date: fromDate,
              to_date: toDate,
              room,
              practitioner,
              attendance_capacity: attendanceCapacity,
              product,
            },
          },
          hide,
        ) => {
          const duration = moment.duration(moment(toDate).diff(moment(fromDate)));
          return (
            <>
              {title && (
                <h3 className="modal-title mb-20">
                  <span className="modal-icon__wrapper">
                    <Checkmark />
                  </span>
                  {title}
                </h3>
              )}
              <h3 className="modal-subtitle h2">{`${product.name} at ${room.facility.name}`}</h3>
              <div className="modal-columns">
                <div className="left-column">
                  <table className="modal-details__table">
                    <tbody>
                      <tr>
                        <td>Time</td>
                        <td>{moment(fromDate).format('HH:mm dddd DD MMMM')}</td>
                      </tr>
                      <tr>
                        <td>Duration</td>
                        <td>{`${duration.asMinutes()} mins`}</td>
                      </tr>
                      <tr>
                        <td>Location</td>
                        <td>{room.name}</td>
                      </tr>
                      <tr>
                        <td>Instructor</td>
                        <td>{practitioner ? practitioner.name : null}</td>
                      </tr>
                      <tr>
                        <td>Category</td>
                        <td>{product ? product.class_category : ''}</td>
                      </tr>
                      <tr>
                        <td>Class capacity</td>
                        <td>{attendanceCapacity}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="right-column">
                  <div>
                    {product && product.what_to_bring && (
                      <div className="mb-10">
                        <strong>What should I bring?</strong>
                        <p className="class-what-to-bring">{product.what_to_bring}</p>
                      </div>
                    )}
                    {cancelTime !== undefined && cancelTime > 0 ? (
                      <p className="mb-10">
                        {`If you wish to cancel this class please do so at least ${cancelTime} hours beforehand to give others the
                                opportunity to attend`}
                      </p>
                    ) : null}
                    <div className="add-to-calendar-link__wrapper">
                      <AddToCalendar
                        event={{
                          title: `${product.name} at ${room.facility.name}`,
                          description: `What to bring:\n${product ? product.what_to_bring : ''}`,
                          location: `${room.name}, ${room.facility.name}`,
                          startTime: fromDate,
                          endTime: toDate,
                        }}
                      />
                      <span className="icon__wrapper">
                        <ArrowRight />
                      </span>
                    </div>

                    <button
                      type="button"
                      className="modal-button modal-outline__button mt-10"
                      onClick={() => hide()}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Modal>

      <FlatList
        data={gymClasses}
        renderItem={item => {
          const {
            sfid,
            practitioner,
            room,
            product,
            from_date: fromDate,
            to_date: toDate,
            my_booking: myBooking,
          } = item;

          const details = {
            reservation: item,
            cancelTime: (() => {
              const status = myBooking ? myBooking.status.toUpperCase() : null;
              let cancelTime = null;

              if (status === BOOKING_STATES.booked) {
                cancelTime = item.cancellation_time;
              } else if (status === BOOKING_STATES.waitlist) {
                cancelTime = item.leave_wait_list_time;
              } else {
                cancelTime = item.has_waitlist ? item.leave_wait_list_time : item.cancellation_time;
              }

              return cancelTime;
            })(),
          };
          return (
            <div
              className={`class__wrapper m--${item.type.toLowerCase()} ${myBooking ? `m--${myBooking.status.replace(/\s+/g, '-').toLowerCase()}` : ''}`}
              key={sfid}
            >
              <div className="class-content__wrapper">
                <div className="class-time__wrapper">
                  {(() =>
                    !showDate ? null : (
                      <div className="class-date bold">{`${moment(fromDate).format('dddd Do MMM YYYY')}`}</div>
                    ))()}
                  <div className="class-time bold">{`${moment(fromDate).format('HH:mm')} - ${moment(toDate).format('HH:mm')}`}</div>
                  {item.action !== null && <ClassStatus item={item} mobile />}
                </div>
                <div className="class-details__wrapper">
                  <div className="class-title bold">
                    {product.name}
                    <a
                      className="link title-view-details__link desktop-only"
                      onClick={() => detailOrDescriptionModalRef.current.show(details)}
                    >
                      View details
                    </a>
                  </div>
                  <div className="class__detail">
                    <Location />
                    {room.name}
                    {' at '}
                    {room.facility.name}
                  </div>
                  <div className="class__detail">
                    {practitioner && practitioner.class_symbol ? (
                      <img className="class-image" src={practitioner.class_symbol} />
                    ) : (
                      <PersonPlus />
                    )}
                    {practitioner ? practitioner.name : null}
                  </div>
                </div>

                <div className="cta__wrapper" data-sfid={sfid} ref={el => addToRefs(el)}>
                  {!hideOverlay && (
                    <>
                      <div
                        className={`cta-background
                        ${item.action !== null && item.action.type !== null ? `m--${item.action.type.toLowerCase()}` : ''}
                        ${item.has_waitlist && 'm--has-waitlist'}
                      `}
                      >
                        {renderDetails({
                          ...item,
                          showDetails: () => detailOrDescriptionModalRef.current.show(details),
                        })}
                        <CTAButton
                          action={item.action}
                          onAction={action => {
                            if (action === null) return;

                            const { type, ...data } = action;
                            const modal = modalTypes[type];
                            if (modal !== undefined) modal.current.show(data);
                          }}
                        />
                      </div>
                      <a
                        className="link view-details__link mobile-only"
                        onClick={() => detailOrDescriptionModalRef.current.show(details)}
                      >
                        View details
                      </a>
                    </>
                  )}
                </div>
                {!hideOverlay && (
                  <button
                    className="toggle-class-overlay__button mobile-only"
                    onClick={() => toggleCtaOverlay(sfid)}
                  >
                    <Arrow />
                  </button>
                )}
              </div>
            </div>
          );
        }}
        renderEmpty={() => <p className="no-items-found">No items found</p>}
      />
    </div>
  );
};
