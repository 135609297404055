import { useCallback, useEffect, useMemo, useState } from 'react';

import { findIndex } from 'lodash';

export default (
  items,
  {
    defaultSelected = 0,
    nullable = false,
    dependencies = [items],
    // eslint-disable-next-line no-unused-vars
    isDisabled = item => false,
    // eslint-disable-next-line no-unused-vars
    onSelect = selected => {},
  } = {},
) => {
  const [selected, setSelected] = useState(defaultSelected);

  const select = useCallback(
    index => {
      if (nullable && selected === index) {
        setSelected(-1);
      } else {
        setSelected(index);
      }
    },
    [setSelected, selected, nullable],
  );

  useEffect(() => {
    setSelected(defaultSelected);
  }, dependencies);

  const [selectedItem, selectableItems] = useMemo(
    () => [
      selected >= 0 && selected < items.length ? items[selected] : null,
      items.map((item, index) => {
        const disabled = isDisabled(item);
        return {
          item,
          disabled,
          select: disabled ? () => {} : () => select(index),
          selected: !disabled && selected === index,
        };
      }),
    ],
    [items, selected],
  );

  useEffect(() => {
    if (selected >= 0 && selected < items.length && selectableItems[selected].disabled) {
      setSelected(-1);
    }
    onSelect(selected !== -1 ? selectableItems[selected] : undefined);
  }, [selected, selectableItems, setSelected]);

  useEffect(() => {
    if (!nullable && selectedItem === null) {
      setSelected(findIndex(selectableItems, ({ disabled }) => !disabled));
    }
  }, [nullable, selectedItem, setSelected, selectableItems]);

  return [selectedItem, selectableItems];
};
