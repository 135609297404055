import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import errorActions from '../actions/errorActions';
import Modal from './Modal';

export default () => {
  const { dismiss } = errorActions();
  const error = useSelector(state => state.error);
  const errorModalRef = useRef();

  const { title, text, close } = useMemo(() => {
    switch (error && error.error) {
      case null:
        return {};
      case 'CONFLICT':
        return {
          title: 'Something went wrong',
          text: 'Sorry, your selection may no longer be available, or you may have a clash. Please choose something else.',
          close: () => window.location.reload(),
        };
      case 'CUSTOM':
        return {
          title: error.title ? error.title : 'We’re sorry, something’s gone wrong.',
          text: error.text ? error.text : 'Please try refreshing your page.',
          close: () => window.location.reload(),
        };
      case 'ACCESS_REVOKED':
        return {
          title: 'Sorry, you are unable to book new classes',
          text:
            'You have failed to attend booked classes on 3 occasions within a calendar month. This means that ' +
            'you will not be able to book any further classes for a period of 7 days. If you think this is an error ' +
            'please contact your fitness and wellbeing centre to confirm.',
          close: () => dismiss(),
        };
      default:
        return {
          title: 'Connection timed out',
          text: 'Please try refreshing your page.',
          close: () => dismiss(),
        };
    }
  }, [error, dismiss]);

  useEffect(() => {
    if (error) {
      errorModalRef.current.show();
    } else {
      errorModalRef.current.hide();
    }
  }, [error, errorModalRef]);

  return (
    <Modal type="generic_error" ref={errorModalRef} onHide={() => close && close()}>
      {() => (
        <>
          <h3 className="modal-subtitle">{title}</h3>
          <p className="modal-text">{text}</p>
          <button type="button" className="modal-button modal-cta__button" onClick={() => close()}>
            OK
          </button>
        </>
      )}
    </Modal>
  );
};
