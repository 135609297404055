import createReducer from '../utils/createReducer';

const defaultState = {
  eligibility: null,
  gymEligibility: false,
  userGym: null,
  serverTime: null,
  info: {
    firstName: '',
    lastName: '',
    emails: [],
  },
};

export default createReducer('user', defaultState, {
  set: (state, payload) => ({
    ...state,
    ...(payload || {}),
  }),
});
