import { useEffect } from 'react';
import useAuthentication from './useAuthentication';
import useBookingEngine from './useBookingEngine';
import useConsultantBookingsApi from './useConsultantBookingsApi';

export default () => {
  const { login, logout } = useAuthentication();
  const bookingEngine = useBookingEngine();
  const consultantBooking = useConsultantBookingsApi();

  useEffect(() => {
    global.devtools = {
      ...global.devtools,
      login,
      logout,
      bookingEngine,
      consultantBooking,
    };
  }, [bookingEngine, login]);
};
