import React from 'react';
import { useHistory, useParams } from 'react-router';

import './HospitalConfirm.sass';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import AddToCalendar from 'react-add-to-calendar';
import { ReactComponent as Checkmark } from '../static/img/checkmark.svg';
import { ReactComponent as ArrowRight } from '../static/img/arrow-right.svg';

export default () => {
  const history = useHistory();
  const { reservation, items, selected_event: selectedEvent } = useSelector(({ events }) => events);

  if (!reservation) {
    const { resourceId, reservationId } = useParams();
    history.push(`/hospitals/${resourceId}/events/${reservationId}/`);
    return '';
  }

  const { consultant, alternative_venue: venue } = reservation;
  let selectedReservation = {};
  if (reservation) {
    selectedReservation = reservation.sfid === selectedEvent ? reservation : items[selectedEvent];
  }
  const { from_date: fromDate, to_date: toDay, room, product, title } = selectedReservation;
  const mDate = moment(fromDate).startOf('day');
  const mStart = moment(fromDate);
  const mEnd = moment(toDay);

  if (reservation) {
    global.TagManager.pushEvent({
      bookingSuccess: true,
      productStart: fromDate,
      resourceId: room.facility.sfid,
      locationName: room.facility.name,
      reservationId: reservation.sfid,
      reservationName: product.name,
      type: product.event_type,
    });
  }

  return (
    <div className="page m--hospitalconfirm">
      <div className="pattern" />
      <div className="header__wrapper">
        <div className="header">
          <div className="h1">Book event</div>
        </div>
      </div>
      <div className="booked">
        <span className="icon__wrapper">
          <Checkmark />
        </span>{' '}
        Your place has been reserved
      </div>
      <span className="divider mb-25" />
      <div className="product-title">{title}</div>
      <div className="details__wrapper">
        <table className="details">
          <tr>
            <td>Time</td>
            <td>
              {mStart.format('HH:mm')} - {mEnd.format('HH:mm')} {mDate.format('Do MMMM YYYY')}
            </td>
          </tr>
          <tr>
            <td>Duration</td>
            <td>{`${mEnd.diff(mStart, 'minutes')} minutes`}</td>
          </tr>
          <tr>
            <td>Location</td>
            <td>
              {venue && venue.alternative_venue ? (
                <>
                  <div>{venue.alternative_venue_name}</div>
                  <div>{venue.alternative_venue_adress_line_1}</div>
                  <div>{venue.alternative_venue_adress_line_2}</div>
                  <div>{venue.alternative_venue_postcode}</div>
                </>
              ) : (
                <div>{`${room && room.facility ? room.facility.name : 'No facility set'}`}</div>
              )}
            </td>
          </tr>
          <tr>
            <td>Consultant</td>
            <td>{consultant}</td>
          </tr>
        </table>
        <div>
          <div className="add-to-calendar__link">
            <AddToCalendar
              event={{
                title: `${title}`,
                location:
                  venue && venue.alternative_venue
                    ? `${venue.alternative_venue_adress_line_1}, ${venue.alternative_venue_adress_line_2}, ${venue.alternative_venue_postcode}`
                    : room && room.facility
                      ? room.facility.name
                      : undefined,
                description: '',
                startTime: mStart.format(),
                endTime: mEnd.format(),
              }}
            />
            <span className="icon__wrapper">
              <ArrowRight />
            </span>
          </div>
        </div>
      </div>
      <span className="divider mb-25" />
    </div>
  );
};
