import user from '../reducers/user';
import useActions from '../hooks/useActions';

export default bookingEngine =>
  useActions({
    parseEligibility: profile => dispatch => {
      const parsedEligibility = profile && JSON.parse(profile.Eligibility);
      const payload = {
        eligibility: parsedEligibility,
        gymEligibility: 'A_GYM' in parsedEligibility && parsedEligibility.A_GYM === 'A',
        info: {
          firstName: profile.given_name,
          lastName: profile.family_name,
          emails: profile.emails,
        },
      };

      dispatch({
        type: user.types.set,
        payload,
      });

      return payload;
    },

    registerPushToken: pushToken => async dispatch => {
      bookingEngine.registerPushToken({ pushToken });
    },

    getServerTime: () => async dispatch => {
      const item = await bookingEngine.serverTime();

      await dispatch({
        type: user.types.set,
        payload: {
          serverTime: item.current_time,
        },
      });

      return item.current_time;
    },

    getUserGym: siteId => async dispatch => {
      const item = await bookingEngine.gymBySiteId({
        siteId,
      });

      await dispatch({
        type: user.types.set,
        payload: {
          userGym: item,
        },
      });

      return item;
    },
  });
