import modals from '../reducers/modals';
import useActions from '../hooks/useActions';

export const hideModal = type => ({
  type: modals.types.hideModal,
  payload: { type },
});

export const showModal = (type, data = {}) => ({
  type: modals.types.showModal,
  payload: {
    type,
    data,
  },
});

export default () =>
  useActions({
    hideModal,
    showModal,
  });
