import React from 'react';

import { ReactComponent as Checkmark } from '../static/img/checkmark.svg';

import './HealthAssessmentHeader.sass';

export default ({ type, name = 'Book your Health Assessment' }) => {
  return (
    <>
      <div className="pattern" />
      <div className="header__wrapper">
        <div className="header">
          {type === 'confirmed' && (
            <div className="ha-confirmed-checkmark">
              <Checkmark />
            </div>
          )}
          <h1 className="h1">{name}</h1>
        </div>
      </div>
    </>
  );
};
