import React from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as Warning } from '../static/img/warning.svg';

import './CommunicationCard.sass';

export default ({ type, title, icon = false, copy, link, linkText }) => {
  return (
    <div className={`communication-card ${type} icon-${icon}`}>
      {(icon || title) && (
        <div className="top">
          {icon && (type === 'warning' || type === 'error') && <Warning />}
          {title && <p className={`title ${type}`}>{title}</p>}
        </div>
      )}
      <p className="copy">
        <span dangerouslySetInnerHTML={{ __html: copy }} />
        {link && (
          <>
            {' '}
            <Link to={link}>{linkText}</Link>.
          </>
        )}
      </p>
    </div>
  );
};
