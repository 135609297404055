import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import HealthAssessmentHeader from '../components/HealthAssessmentHeader';
import LocationFinder from '../components/LocationFinder';
import BackBtn from '../components/BackBtn';

import { useHealthAssessmentCtx } from '../contexts';

import './HealthAssessmentLocations.sass';

const HealthAssessmentLocations = () => {
  const history = useHistory();
  const {
    haCtxSelectors: { isEmptyState },
  } = useHealthAssessmentCtx();
  const isEmptyStateData = isEmptyState();

  // Redirect users to start of booking flow if the state is empty
  useEffect(() => {
    const payorId = sessionStorage.getItem('PayorID');
    const activityId = sessionStorage.getItem('ActivityID');
    const idaasId = sessionStorage.getItem('IDaaSID');
    if (isEmptyStateData) {
      history.push(
        `/healthAssessmentDetails/?PayorID=${payorId}&ActivityID=${activityId}&IDaaSID=${idaasId}`,
      );
    }
  }, [history, isEmptyStateData]);

  return (
    <div className="health-assessment-locations-container">
      <HealthAssessmentHeader />
      <BackBtn name="Back to: Choose your Health Assessment" />

      <h2 className="page-title">Choose a location</h2>

      <p className="intro-text">
        Simply enter your location to find your nearest Health Assessment.
      </p>

      <LocationFinder />
    </div>
  );
};

export default HealthAssessmentLocations;
