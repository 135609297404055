import { useMemo } from 'react';
import BookingEngine from '../services/BookingEngine';
import useAuthentication from './useAuthentication';
import errorActions from '../actions/errorActions';

const useBookingEngine = () => {
  const { refresh, access_token: accessToken } = useAuthentication();
  const { log: logError } = errorActions();

  return useMemo(() => BookingEngine.getInstance(accessToken, refresh, logError), [accessToken]);
};

export default useBookingEngine;
