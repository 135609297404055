import moment from 'moment-timezone';

export default (item, { cancel }) => ({
  name: 'Cancel',
  type: 'CANCEL_BOOKING',
  call: () => ({
    type: 'confirm',
    title: `Are you sure you want to cancel ${item.title} on ${moment(item.from_date).format('dddd Do MMMM HH:mm')}?`,
    confirm: {
      call: () => cancel(item.sfid, item.my_booking.sfid),
      name: 'Cancel and continue',
      type: 'CANCEL',
    },
    deny: {
      call: () => {},
      name: 'No, go back',
      type: 'BACK',
    },
  }),
});
