export const BOOKING_STATES = {
  booked: 'BOOKED',
  waitlist: 'WAITLIST',
  pending: 'PENDING',
  userCancelled: 'USER CANCELLED',
};

export const BOOKING_STATE_TITLES = {
  BOOKED: 'Booked',
  WAITLIST: 'Waitlist',
};

export const CANCELABLE_STATES = [BOOKING_STATES.booked, BOOKING_STATES.waitlist];
// HA script status
export const ScriptStates = {
  Loading: 'loading',
  Ready: 'ready',
  Error: 'error',
  Unknown: 'unknown',
};
