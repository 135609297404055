import createReducer from '../utils/createReducer';

const defaultState = {};

const addBookableItems = (state, hmotTimeslots) =>
  hmotTimeslots.reduce(
    (newState, item) => ({
      ...newState,
      [item.sfid]: {
        ...newState[item.sfid],
        ...item,
      },
    }),
    state,
  );

export default createReducer('hmotTimeslots', defaultState, {
  addBookableItems,
});
