import React from 'react';
import { useParams } from 'react-router';
import useEvents from '../hooks/useEvents';
import EventProduct from '../components/EventProduct';
import EventForm from '../components/EventForm';
import EventTimeSlots from '../components/EventTimeSlots';

import './Hospital.sass';

export default () => {
  const { resourceId, reservationId } = useParams();

  const { reservation, timeslots, selectedEvent, profile, createEventReservation } = useEvents(
    resourceId,
    reservationId,
  );

  return (
    <div className="page m--hospital">
      <div className="pattern" />
      <div className="header__wrapper">
        <div className="header">
          <div className="h1">Book event</div>
        </div>
      </div>
      <span className="divider" />
      {reservation && reservation.product.event_type === 'Presentation Only' && !selectedEvent && (
        <EventProduct item={reservation} />
      )}
      {reservation && reservation.product.event_type === '1-2-1 slots Only' && !selectedEvent && (
        <EventTimeSlots reservation={reservation} items={timeslots} />
      )}
      {selectedEvent && (
        <EventForm
          selectedEvent={selectedEvent}
          profile={profile}
          createEventReservation={createEventReservation}
        />
      )}
    </div>
  );
};
