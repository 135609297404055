import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';

import FormField from '../components/FormField';
import HealthAssessmentHeader from '../components/HealthAssessmentHeader';
import CTAButton from '../components/CTAButton';
import BackBtn from '../components/BackBtn';

import { CONFIRM_FORM_ALTERNATIVE_NUMBER, CONFIRM_FORM_PRIMARY_NUMBER } from '../utils/forms';
import useForm from '../hooks/useForm';

import { useHealthAssessmentCtx } from '../contexts';

import { ReactComponent as Checkmark } from '../static/img/checkmark.svg';
import { ReactComponent as Location } from '../static/img/location.svg';
import { ReactComponent as PersonPlus } from '../static/img/person-plus.svg';

import './HealthAssessmentConfirm.sass';

const formComplete = confirmForm => {
  return Object.values(confirmForm).reduce(
    (valid, field) => valid && (field.touched ? field.valid : field.isValid()),
    true,
  );
};

export default () => {
  const history = useHistory();
  const [typeOfPhone, setTypeOfPhone] = useState('primary');
  let confirmForm = useForm(
    typeOfPhone === 'primary' ? CONFIRM_FORM_PRIMARY_NUMBER : CONFIRM_FORM_ALTERNATIVE_NUMBER,
  );
  const {
    state,
    haCtxSelectors: { haConfirmPage, isEmptyState, haSlotsPage },
    haCtxActions,
  } = useHealthAssessmentCtx();
  const [terms, setTerms] = useState(false);

  const isEmptyStateData = isEmptyState();
  const haSlots = haSlotsPage();
  const {
    appointmentId,
    patient,
    startTime,
    endTime,
    practitionerGenderPreference,
    haTypeTitle,
    haLocationName,
  } = haConfirmPage();

  // Redirect users to start of booking flow if the state is empty
  useEffect(() => {
    const payorId = sessionStorage.getItem('PayorID');
    const activityId = sessionStorage.getItem('ActivityID');
    const idaasId = sessionStorage.getItem('IDaaSID');
    if (isEmptyStateData) {
      history.push(
        `/healthAssessmentDetails/?PayorID=${payorId}&ActivityID=${activityId}&IDaaSID=${idaasId}`,
      );
    }
  }, []);

  // Store data and call API - PUT Appointment, PUT Patient & direct user to next page
  const toggleTerms = useCallback(() => setTerms(!terms), [terms]);
  const updateConfirmForm = useCallback(() => {
    // Store phone number, marketing preference, T&C compliance to state
    const boolToBinaryString = value => Number(value).toString();
    const confirmData = {
      details: {
        phoneNumber: confirmForm.phone_number?.value || confirmForm.alternative_number?.value,
      },
      marketingPreferences: {
        emailOptIn: boolToBinaryString(confirmForm.communication_preferences.value.email),
        phoneOptIn: boolToBinaryString(confirmForm.communication_preferences.value.phone),
        textOptIn: boolToBinaryString(confirmForm.communication_preferences.value.sms),
      },
      termsAndConditionsPrivacyPolicy: terms,
      appointmentStatus: 'booked',
    };
    haCtxActions.addConfirm(confirmData);
    
    // Call PUT Appointment (proposed -> pending - > booked) API
    // Appointment Comment to include Practictioner Gender Preference, if Female or Male
    const commentValue = 
      practitionerGenderPreference != "Unknown" ? 
        practitionerGenderPreference + " Dr pref / " + confirmForm.special_requirements?.value :
        confirmForm.special_requirements?.value;
    const putAppointment = async () => await haCtxActions.putAppointment(appointmentId, commentValue);
    putAppointment();

    // Call PUT Patient API
    const parameters = {
      patientId: patient,
      ...(typeOfPhone === 'primary'
        ? { mobilePhone: confirmForm.phone_number?.value }
        : { daytimePhone: confirmForm.alternative_number?.value }),
      gender: haSlots.gender,
      sexAssignedAtBirth: haSlots.sexAssignedAtBirth,
      practitionerGenderPreference,
      addressLookup: confirmForm.addresslookup?.value,
    };
    const putPatient = async () => await haCtxActions.putPatient(parameters);
    putPatient();
    // Direct user to next page
    history.push('/healthAssessmentConfirmed/');
  }, [confirmForm]);

  const appointmentDate = moment(startTime).format('dddd Do MMMM');
  const slotStartTime = moment(startTime).format('HH:mm');
  const slotEndTime = moment(endTime).format('HH:mm');

  const practitionerGenderPreferenceString = useMemo(() => {
    return practitionerGenderPreference !== 'Unknown'
      ? practitionerGenderPreference
      : 'No Preference';
  }, [practitionerGenderPreference]);

  return (
    <div className="health-assessment-confirm-container">
      <HealthAssessmentHeader />
      <BackBtn name="Back to: Choose appointment" />

      <h2 className="page-title">Complete your booking</h2>

      <div className="intro-text">
        <p>
          Please check you‘re happy with your appointment, and enter some final details, before
          confirming your booking below.
        </p>
        <p>*Mandatory fields</p>
      </div>

      <h3 className="page-subtitle">Appointment details</h3>

      <div className="appointment-card">
        <div className="top">
          <p className="time">{`${slotStartTime} - ${slotEndTime}`}</p>
          <p className="date">{appointmentDate}</p>
        </div>
        <hr className="line" />
        <div className="bottom">
          <h4 className="type">{haTypeTitle}</h4>
          <p className="location">
            <Location />
            {haLocationName}
          </p>
          <p className="gender">
            <PersonPlus />
            Gender of practitioner:&nbsp;
            <span className="gender-name">{practitionerGenderPreferenceString}</span>
          </p>
        </div>
      </div>

      <hr className="divider" />

      <h3 className="page-subtitle">Your contact details*</h3>

      <div className="phone-field-wrapper mb-50">
        {typeOfPhone === 'primary' ? (
          <div key="primary">
            <FormField
              field={confirmForm.phone_number}
              errorEmpty="Please enter your phone number"
              errorText="Enter a number starting with 0, the correct amount of digits, and remove any spaces or invalid characters."
            />
            <button
              className="phone-switch-button"
              onClick={() => {
                confirmForm.phone_number?.setValue('');
                confirmForm.alternative_number?.setValue('');
                setTypeOfPhone('secondary');
              }}
            >
              Don&apos;t use a mobile number?
            </button>
          </div>
        ) : (
          <div key="secondary">
            <FormField
              field={confirmForm.alternative_number}
              errorEmpty="Please enter your phone number"
              errorText="Enter a number starting with 0, the correct amount of digits, and remove any spaces or invalid characters."
            />
            <button
              className="phone-switch-button"
              onClick={() => {
                confirmForm.phone_number?.setValue('');
                confirmForm.alternative_number?.setValue('');
                setTypeOfPhone('primary');
              }}
            >
              Prefer to give us your mobile number?
            </button>
          </div>
        )}
      </div>

      <h3 className="page-subtitle">Your home address*</h3>
      <div className="address-fields mb-40">
        <FormField field={confirmForm.addresslookup} errorEmpty="Please enter your address." />
      </div>

      <h3 className="page-subtitle">Accessibility requirements</h3>
      <p className="special-requirements-text">
        We are able to offer physical, mental, and neurodiversity-related assistance to help make
        your time with us as comfortable as possible. If you need help before, during, or after your
        appointment, please expand on how we can help below:
      </p>
      <div className="special-requirements-field">
        <FormField field={confirmForm.special_requirements} />
      </div>

      <div className="communication-wrapper mb-40">
        <p className="mb-40">
          To continue to receive communications from Nuffield Health about our exclusive offers,
          products and services, then please tell us how you&apos;d like to be contacted by ticking
          the relevant boxes below:
        </p>
        <div className="mb-40">
          <FormField field={confirmForm.communication_preferences} />
        </div>
        <p className="mb-30">
          On occasion Nuffield Health may contact you with pertinent service information in regards
          to services we provide. Note that you can either amend or withdraw your consent at any
          time.
        </p>
        <p>
          For information about where your personal data may be processed, how it may be processed
          and for details of our Data Protection Officer, please see our Privacy Policy.
        </p>
      </div>

      <hr className="divider" />

      <p className="cancellation-title">Cancellation policy</p>
      <p className="cancellation-text">
        Failure to give at least three full working days’ notice to cancel or reschedule your appointment
        will result in a charge of 50% of the fee. Same day cancellation or if you fail to arrive or attend
        more than 15 minutes late, the full fee may be charged. Please consult your HR, Rewards, or Benefits team
        to understand who is responsible for any late cancellation charges incurred.
      </p>

      <div className="custom-checkbox__wrapper">
        <input className="custom-checkbox__value" id="toc" type="checkbox" onChange={toggleTerms} />
        <label className="custom-checkbox__label" htmlFor="toc">
          <span className="custom-checkbox__faux">
            <Checkmark />
          </span>
          <span>I agree to the </span>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.nuffieldhealth.com/terms/nuffield-health-website-terms-and-conditions"
          >
            terms and conditions
          </a>
          <span> and </span>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.nuffieldhealth.com/privacy"
          >
            privacy policy
          </a>
        </label>
      </div>
      <div className="health-assessment-confirm-ctas-wrapper">
        <CTAButton
          action={{
            call: formComplete(confirmForm) && terms ? () => updateConfirmForm : null,
            type: 'CONFIRM_BOOKING',
            name: 'Confirm booking',
          }}
          isNotAsync
          onAction={updateConfirmForm}
        />
      </div>
    </div>
  );
};
