import React, { useEffect, useMemo, useState, useReducer } from 'react';
import useBookingEngine from '../hooks/useBookingEngine';

import './AddressLookup.sass';

const REGEX_POSTCODE = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/;
const REGEX_LINEONE = /^[a-zA-Z0-9 '&,\\.|\-/\n]*$/;
const REGEX_LINETWO = /^[a-zA-Z0-9 '&,\\.|-]*$/;
const REGEX_TOWNCITY = /^[a-zA-Z0-9 '&,\\.|-]{0,40}$/;
const REGEX_MANUALPOSTCODE = /^[a-zA-Z0-9 ]*$/;
const REGEX_WHITESPACE = /^ {1,}$/;

const ActionTypes = {
  SET_POSTCODE: 'SET_POSTCODE',
  SET_LINE_ONE: 'SET_LINE_ONE',
  SET_LINE_TWO: 'SET_LINE_TWO',
  SET_TOWN_CITY: 'SET_TOWN_CITY',
  SET_COUNTY: 'SET_COUNTY',
  SET_MANUAL_POSTCODE: 'SET_MANUAL_POSTCODE',
  SET_TOUCHED_TRUE: 'SET_TOUCHED_TRUE',
  SET_TOUCHED_FALSE: 'SET_TOUCHED_FALSE',
  SET_INVALID_TRUE: 'SET_INVALID_TRUE',
  SET_INVALID_FALSE: 'SET_INVALID_FALSE',
  SET_EMPTY_TRUE: 'SET_EMPTY_TRUE',
  SET_EMPTY_FALSE: 'SET_EMPTY_FALSE',
};

const mainReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_POSTCODE:
      return { ...state, postcode: action.payload };
    case ActionTypes.SET_LINE_ONE:
      return { ...state, lineOne: action.payload };
    case ActionTypes.SET_LINE_TWO:
      return { ...state, lineTwo: action.payload };
    case ActionTypes.SET_TOWN_CITY:
      return { ...state, townCity: action.payload };
    case ActionTypes.SET_COUNTY:
      return { ...state, county: action.payload };
    case ActionTypes.SET_MANUAL_POSTCODE:
      return { ...state, manualPostcode: action.payload };
    case ActionTypes.SET_TOUCHED_TRUE:
      return { ...state, touched: { ...state.touched, [action.payload]: true } };
    case ActionTypes.SET_TOUCHED_FALSE:
      return { ...state, touched: { ...state.touched, [action.payload]: false } };
    case ActionTypes.SET_INVALID_TRUE:
      return { ...state, invalid: { ...state.invalid, [action.payload]: true } };
    case ActionTypes.SET_INVALID_FALSE:
      return { ...state, invalid: { ...state.invalid, [action.payload]: false } };
    case ActionTypes.SET_EMPTY_TRUE:
      return { ...state, empty: { ...state.empty, [action.payload]: true } };
    case ActionTypes.SET_EMPTY_FALSE:
      return { ...state, empty: { ...state.empty, [action.payload]: false } };
    default:
      return state;
  }
};

const initialState = {
  postcode: '',
  lineOne: '',
  lineTwo: '',
  townCity: '',
  county: '',
  manualPostcode: '',
  touched: {
    postcode: false,
    lineOne: false,
    townCity: false,
    manualPostcode: false,
  },
  invalid: {
    postcode: true,
    lineOne: false,
    lineTwo: false,
    townCity: false,
    manualPostcode: false,
  },
  empty: {
    lineOne: true,
    townCity: true,
    manualPostcode: true,
  },
};

export default function AddressLookup({ setValue, id, includeCounty = false }) {
  const [loadingAddresses, setLoadingAddresses] = useState(false);
  const [postcodeId, setPostcodeId] = useState('');
  const [addressId, setAddressId] = useState('');
  const [loqateAddresses, setLoqateAddresses] = useState([]);
  const [userAddress, setUserAddress] = useState({});
  const [showManualFields, setShowManualFields] = useState(false);
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const { postcode, lineOne, lineTwo, townCity, county, manualPostcode, touched } = state;
  const bookingEngine = useBookingEngine();

  const validPostcode = useMemo(
    () => (postcode && postcode.match(REGEX_POSTCODE) ? postcode : null),
    [postcode],
  );

  const setTouched = field => {
    dispatch({ type: ActionTypes.SET_TOUCHED_TRUE, payload: field });
  };

  const setUntouched = field => {
    dispatch({ type: ActionTypes.SET_TOUCHED_FALSE, payload: field });
  };

  const clearForm = clearPostCode => {
    setUserAddress([]);
    setLoqateAddresses([]);
    setShowManualFields(false);
    if (clearPostCode) {
      dispatch({ type: ActionTypes.SET_POSTCODE, payload: '' });
      dispatch({ type: ActionTypes.SET_LINE_ONE, payload: '' });
      dispatch({ type: ActionTypes.SET_LINE_TWO, payload: '' });
      dispatch({ type: ActionTypes.SET_TOWN_CITY, payload: '' });
      dispatch({ type: ActionTypes.SET_COUNTY, payload: '' });
      dispatch({ type: ActionTypes.SET_MANUAL_POSTCODE, payload: '' });
      setUntouched('postcode');
      setUntouched('lineOne');
      setUntouched('townCity');
      setUntouched('county');
      setUntouched('manualPostcode');
    }
  };

  useEffect(() => {
    setPostcodeId(null);
    dispatch({ type: ActionTypes.SET_INVALID_TRUE, payload: 'postcode' });
    if (validPostcode) {
      setLoadingAddresses(true);
      dispatch({ type: ActionTypes.SET_INVALID_FALSE, payload: 'postcode' });
      bookingEngine
        .validatePostalCode({ id, postalCode: validPostcode })
        .then(({ items }) => {
          if (items[0].type !== 'Postcode') {
            setLoqateAddresses([]);
          } else {
            setPostcodeId(items[0].id);
          }
        })
        .catch(() => {
          setPostcodeId(null);
        })
        .finally(() => setLoadingAddresses(false));
    }
  }, [validPostcode]);

  useEffect(() => {
    if (!lineOne || (lineOne && lineOne.match(REGEX_WHITESPACE))) {
      dispatch({ type: ActionTypes.SET_EMPTY_TRUE, payload: 'lineOne' });
      dispatch({ type: ActionTypes.SET_INVALID_FALSE, payload: 'lineOne' });
    } else if (lineOne && lineOne.match(REGEX_LINEONE)) {
      dispatch({ type: ActionTypes.SET_EMPTY_FALSE, payload: 'lineOne' });
      dispatch({ type: ActionTypes.SET_INVALID_FALSE, payload: 'lineOne' });
    } else {
      dispatch({ type: ActionTypes.SET_EMPTY_FALSE, payload: 'lineOne' });
      dispatch({ type: ActionTypes.SET_INVALID_TRUE, payload: 'lineOne' });
    }
  }, [lineOne]);

  useEffect(() => {
    if (!lineTwo || (lineTwo && lineTwo.match(REGEX_LINETWO))) {
      dispatch({ type: ActionTypes.SET_INVALID_FALSE, payload: 'lineTwo' });
    } else {
      dispatch({ type: ActionTypes.SET_INVALID_TRUE, payload: 'lineTwo' });
    }
  }, [lineTwo]);

  useEffect(() => {
    if (!townCity || (townCity && townCity.match(REGEX_WHITESPACE))) {
      dispatch({ type: ActionTypes.SET_EMPTY_TRUE, payload: 'townCity' });
      dispatch({ type: ActionTypes.SET_INVALID_FALSE, payload: 'townCity' });
    } else if (townCity && townCity.match(REGEX_TOWNCITY)) {
      dispatch({ type: ActionTypes.SET_EMPTY_FALSE, payload: 'townCity' });
      dispatch({ type: ActionTypes.SET_INVALID_FALSE, payload: 'townCity' });
    } else {
      dispatch({ type: ActionTypes.SET_EMPTY_FALSE, payload: 'townCity' });
      dispatch({ type: ActionTypes.SET_INVALID_TRUE, payload: 'townCity' });
    }
  }, [townCity]);

  useEffect(() => {
    if (!county || (county && county.match(REGEX_TOWNCITY))) {
      dispatch({ type: ActionTypes.SET_INVALID_FALSE, payload: 'county' });
    } else {
      dispatch({ type: ActionTypes.SET_INVALID_TRUE, payload: 'county' });
    }
  }, [county]);

  useEffect(() => {
    if (!manualPostcode || (manualPostcode && manualPostcode.match(REGEX_WHITESPACE))) {
      dispatch({ type: ActionTypes.SET_EMPTY_TRUE, payload: 'manualPostcode' });
      dispatch({ type: ActionTypes.SET_INVALID_FALSE, payload: 'manualPostcode' });
    } else if (manualPostcode && manualPostcode.match(REGEX_MANUALPOSTCODE)) {
      dispatch({ type: ActionTypes.SET_EMPTY_FALSE, payload: 'manualPostcode' });
      dispatch({ type: ActionTypes.SET_INVALID_FALSE, payload: 'manualPostcode' });
    } else {
      dispatch({ type: ActionTypes.SET_EMPTY_FALSE, payload: 'manualPostcode' });
      dispatch({ type: ActionTypes.SET_INVALID_TRUE, payload: 'manualPostcode' });
    }
  }, [manualPostcode]);

  useEffect(() => {
    clearForm();
    if (postcodeId) {
      setLoadingAddresses(true);
      bookingEngine
        .getAddressList({ postcodeId })
        .then(({ items }) => {
          setLoqateAddresses(items);
        })
        .catch(() => {
          setLoqateAddresses([]);
        })
        .finally(() => setLoadingAddresses(false));
    }
  }, [postcodeId]);

  useEffect(() => {
    if (addressId) {
      setLoadingAddresses(true);
      bookingEngine
        .getSpecificAddressDetails({ addressId })
        .then(({ items }) => {
          setUserAddress(items[0]);
          dispatch({ type: ActionTypes.SET_LINE_ONE, payload: items[0].line_one });
          dispatch({ type: ActionTypes.SET_LINE_TWO, payload: items[0].line_two });
          dispatch({ type: ActionTypes.SET_TOWN_CITY, payload: items[0].city });
          dispatch({ type: ActionTypes.SET_COUNTY, payload: items[0].county });
          dispatch({ type: ActionTypes.SET_MANUAL_POSTCODE, payload: items[0].postal_code });
          setShowManualFields(true);
        })
        .catch(() => {
          setUserAddress([]);
        })
        .finally(() => setLoadingAddresses(false));
    }
  }, [addressId]);

  const onManualAddressClick = () => {
    clearForm(true);
    setShowManualFields(true);
    setUntouched('postcode');
  };

  useEffect(() => {
    setValue(userAddress);
  }, [userAddress]);

  const updateField = async (value, field) => {
    switch (field) {
      case 'line_one':
        setUserAddress({ ...userAddress, line_one: value });
        break;
      case 'line_two':
        setUserAddress({ ...userAddress, line_two: value });
        break;
      case 'town_city':
        setUserAddress({ ...userAddress, city: value });
        break;
      case 'county':
        setUserAddress({ ...userAddress, county: value });
        break;
      case 'postcode':
        setUserAddress({ ...userAddress, postal_code: value });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div
        className={`mt-40 zipcode-field__wrapper field ${state.invalid.postcode && state.touched.postcode && 'field--invalid'}`}
      >
        <label className="form-field__label" htmlFor={`gplookup-postcode-${id}`}>
          Postcode
        </label>
        <p className="address-GP-lookup-label">
          Use the postcode field to search for your address.
        </p>
        <input
          type="text"
          id={`gplookup-postcode-${id}`}
          value={state.postcode}
          placeholder="Enter postcode to search"
          className="text__input"
          onChange={e =>
            dispatch({
              type: ActionTypes.SET_POSTCODE,
              payload: e.target.value.toUpperCase(),
            })
          }
          onBlur={() => setTouched('postcode')}
        />
        {state.invalid.postcode && state.touched.postcode && (
          <p className="field-error">
            We don&apos;t recognise that postcode. Please try again or enter your address manually
            below.
          </p>
        )}
        {!showManualFields && (
          <p className="clickableLinks" onClick={onManualAddressClick}>
            Enter Address Manually
          </p>
        )}
      </div>
      {loadingAddresses && (
        <div className="mt-20">
          <div className="addressLookup__spinner" />
          <p>Searching for addresses...</p>
        </div>
      )}
      {loqateAddresses.length > 0 && (
        <div className="mt-20">
          <label className="form-field__label" htmlFor={`gplookup-practice-${id}`}>
            Select your address
          </label>
          <select
            className="select__input"
            onInput={e => setAddressId(e.target.value)}
            defaultValue="???"
          >
            <option value="???" disabled>
              Please select your address
            </option>
            {loqateAddresses.map(list => (
              <option value={list.id} key={list.id}>
                {`${list.text}, ${list.description}`}
              </option>
            ))}
          </select>
        </div>
      )}

      {showManualFields && (
        <>
          <div
            className={`mt-40 field ${((state.empty.lineOne && state.touched.lineOne) || state.invalid.lineOne) && 'field--invalid'}`}
          >
            <label className="form-field__label" htmlFor="address_line_1">
              Address Line 1<sup>*</sup>
            </label>
            <input
              id={`${id}-address_line_1`}
              name="address_line_1"
              type="text"
              value={state.lineOne}
              className="text__input"
              onChange={e => {
                dispatch({ type: ActionTypes.SET_LINE_ONE, payload: e.target.value });
                updateField(e.target.value, 'line_one');
              }}
              onBlur={() => setTouched('lineOne')}
            />
            {state.empty.lineOne && state.touched.lineOne && (
              <p className="field-error"> Please enter address line 1 </p>
            )}
            {state.invalid.lineOne && (
              <p className="field-error">
                {' '}
                Please only enter the following: a-z A-Z 0-9 &apos; & , \ . | -{' '}
              </p>
            )}
          </div>
          <div className={`mt-20 field ${state.invalid.lineTwo && 'field--invalid'}`}>
            <label className="form-field__label" htmlFor="address_line_2">
              Address Line 2
            </label>
            <input
              id={`${id}-address_line_2`}
              name="address_line_2"
              type="text"
              value={state.lineTwo}
              className="text__input"
              onChange={e => {
                dispatch({ type: ActionTypes.SET_LINE_TWO, payload: e.target.value });
                updateField(e.target.value, 'line_two');
              }}
            />
            {state.invalid.lineTwo && (
              <p className="field-error">
                {' '}
                Please only enter the following: a-z A-Z 0-9 &apos; & , \ . | -{' '}
              </p>
            )}
          </div>
          <div
            className={`mt-20 field ${((state.empty.townCity && state.touched.townCity) || state.invalid.townCity) && 'field--invalid'}`}
          >
            <label className="form-field__label" htmlFor="town_city">
              Town / City
              <sup>*</sup>
            </label>
            <input
              id="town_city"
              name="town_city"
              type="text"
              required=""
              autoComplete="on"
              value={state.townCity}
              className="text__input"
              onChange={e => {
                dispatch({ type: ActionTypes.SET_TOWN_CITY, payload: e.target.value });
                updateField(e.target.value, 'town_city');
              }}
              onBlur={() => setTouched('townCity')}
            />
            {state.empty.townCity && state.touched.townCity && (
              <p className="field-error"> Please enter a town / city </p>
            )}
            {state.invalid.townCity && (
              <p className="field-error">
                {' '}
                Please only enter the following: a-z A-Z 0-9 &apos; & , \ . | - Maximum of 40
                characters
              </p>
            )}
          </div>
          {includeCounty && (
            <div className={`mt-20 field ${state.invalid.county && 'field--invalid'}`}>
              <label className="form-field__label" htmlFor="county">
                County
              </label>
              <input
                id="county"
                name="county"
                type="text"
                autoComplete="on"
                value={state.county}
                className="text__input"
                onChange={e => {
                  dispatch({ type: ActionTypes.SET_COUNTY, payload: e.target.value });
                  updateField(e.target.value, 'county');
                }}
                onBlur={() => setTouched('county')}
              />
              {state.invalid.county && (
                <p className="field-error">
                  {' '}
                  Please only enter the following: a-z A-Z 0-9 &apos; & , \ . | - Maximum of 40
                  characters
                </p>
              )}
            </div>
          )}
          <div
            className={`mt-20 zipcode-field__wrapper field ${((state.empty.manualPostcode && state.touched.manualPostcode) || state.invalid.manualPostcode) && 'field--invalid'}`}
          >
            <label className="form-field__label" htmlFor="postcode">
              Postcode
              <sup>*</sup>
            </label>
            <input
              id="postcode"
              name="postcode"
              type="text"
              required=""
              autoComplete="on"
              value={state.manualPostcode}
              className="text__input"
              onChange={e => {
                dispatch({ type: ActionTypes.SET_MANUAL_POSTCODE, payload: e.target.value });
                updateField(e.target.value, 'postcode');
              }}
              onBlur={() => setTouched('manualPostcode')}
            />
            {state.empty.manualPostcode && state.touched.manualPostcode && (
              <p className="field-error"> Please enter a postcode </p>
            )}
            {state.invalid.manualPostcode && (
              <p className="field-error"> Please only enter letter and numbers </p>
            )}
          </div>
          {loqateAddresses.length > 0 && (
            <div className="addressDisclaimerContainer">
              <p>
                Please make sure the details above are correct. If not, please make any necessary
                changes.
              </p>
            </div>
          )}
          <p className="clickableLinks" onClick={() => clearForm(true)}>
            Clear form
          </p>
        </>
      )}
    </>
  );
}
