// Email Validation:
// Alphanumeric character and/or a range of special characters (._%+-) followed by an ampersand(@)
// Grouped Domain containing Alphanumeric characters, hyphen followed by a period(.)
// Ending with a top level domain(TLD) with 2 or more letters
// Note: The grouped domain can match multiple times for TLD such as .co.uk

export default email => {
  return (
    (email && email.match(/^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/) !== null) || !email
  );
};
