import moment from 'moment-timezone';
import createReducer from '../utils/createReducer';

const defaultState = {};

const addBookableItems = (state, { gymClasses, fromDate = null, toDate = null }) => {
  // If fromDate and toDate are defined we will first remove all items between
  // that range from the state.
  const currentState = Object.keys(state)
    .filter(key => !moment(state[key].from_date).isBetween(fromDate, toDate))
    .reduce((res, key) => ((res[key] = state[key]), res), {});
  return gymClasses.reduce(
    (newState, item) => ({
      ...newState,
      [item.sfid]: {
        ...newState[item.sfid],
        ...item,
      },
    }),
    currentState,
  );
};

const addBooking = (state, { reservation, updateCount = false, count, ...booking }) => {
  const updatedCount = {};
  if (updateCount) {
    const field = count.slice(1);
    const current = state[reservation][field];

    updatedCount[field] = count.charAt(0) === '+' ? current + 1 : current - 1;
  }
  return {
    ...state,
    [reservation]: {
      ...state[reservation],
      ...updatedCount,
      my_booking: (() => {
        const { sfid, status, waitlist_position, heroku_id } = booking;
        return { sfid, status, waitlist_position, heroku_id };
      })(),
    },
  };
};

const setStatus = (state, { reservation, status }) => {
  const newState = { ...state };

  if (newState[reservation].my_booking) {
    newState[reservation].my_booking.previous_status = newState[reservation].my_booking.status;
    newState[reservation].my_booking.status = status;
  } else {
    newState[reservation].my_booking = { status, previous_status: null };
  }
  return newState;
};

const addBookings = (state, bookings) =>
  bookings.reduce((accumulator, { reservation, ...booking }) => {
    const newState = { ...accumulator };
    if (!(reservation.sfid in newState)) {
      newState[reservation.sfid] = reservation;
    }

    return addBooking(newState, { ...booking, reservation: reservation.sfid });
  }, state);

const doRefresh = (state, { bookingId }) => {
  const newState = { ...state };

  if (newState[bookingId].my_booking.previous_status == null) {
    newState[bookingId].my_booking = null;
  }

  if (newState[bookingId].my_booking) {
    newState[bookingId].my_booking.status = newState[bookingId].my_booking.previous_status;
  }
  return newState;
};

export default createReducer('gymClasses', defaultState, {
  addBookableItems,
  addBooking,
  addBookings,
  setStatus,
  doRefresh,
});
