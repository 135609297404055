import React, { useState, forwardRef } from 'react';

import './CTAButton.sass';

export default forwardRef(
  (
    {
      action,
      onAction = () => {},
      onError = () => {},
      disabled = false,
      className = '',
      isNotAsync,
    },
    ref,
  ) => {
    const [loading, setLoading] = useState(false);

    if (action === null || action === undefined) {
      return null;
    }

    const onClick = async () => {
      if (disabled || loading) {
        return;
      }

      try {
        setLoading(true);
        onAction(isNotAsync ? action.call() : await action.call());
      } catch (e) {
        onError(e);
      } finally {
        setLoading(false);
      }
    };

    return (
      <button
        type="button"
        onClick={onClick}
        ref={ref}
        disabled={disabled || action.call === null}
        className={`${className || 'primary-btn'} ${action.type !== null ? `m--${action.type.toLowerCase()}` : ''}`}
      >
        {loading ? 'Loading...' : action.name}
      </button>
    );
  },
);
