import { useMemo } from 'react';
import moment from 'moment-timezone';
import useSelect from './useSelect';
import { AFTERNOON, EVENING_FULL, MORNING_FULL } from '../enums/dayParts';

export default (timeslots, dependencies = []) => {
  const [selectedSlot, selectableSlots] = useSelect(timeslots, {
    defaultSelected: -1,
    nullable: true,
    dependencies: [...dependencies, timeslots.length],
  });

  const groups = useMemo(
    () =>
      [MORNING_FULL, AFTERNOON, EVENING_FULL].map(({ name, from, to }) => {
        const mFrom = moment(from, 'HH:mm');
        const mTo = moment(to, 'HH:mm');

        return {
          name,
          timeslots: selectableSlots.filter(({ item: [date] }) =>
            moment(date, 'HH:mm').isBetween(mFrom, mTo, null, '[)'),
          ),
        };
      }),
    [selectableSlots],
  );

  const [, selectableGroups] = useSelect(groups, {
    dependencies: [...dependencies, timeslots.length],
    nullable: true,
  });

  return [selectedSlot, selectableGroups];
};
